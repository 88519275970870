"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file qc_samples.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.QCSamplesServicePaginatedParametersResponse = exports.QCSampleParametersSearchRequest = exports.QCSampleParameterHistoryList = exports.QCSampleParameterHistory = exports.QCSampleParameterHistoryRequest = exports.QCSampleParametersList = exports.QCSampleParameter = exports.QCSamplesServiceParameterUpdateRequest = exports.QCSamplesServiceSearchAllReq = exports.QCSamplesServiceCountReq = exports.QCSamplesServiceFilterReq = exports.QCSamplesServicePaginationResponse = exports.QCSamplesServicePaginationReq = exports.QCSamplesWithMetadataList = exports.QCSamplesList = exports.QCSampleWithMetadata = exports.QCSample = exports.QCSampleAncillaryParameters = exports.QCSamplesServiceUpdateRequest = exports.QCSamplesServiceCreateRequest = exports.LogbookLogQCSampleLC = exports.QC_SAMPLE_PARAMETER_SORT_KEY = exports.QC_SAMPLE_SORT_KEY = exports.QC_SAMPLE_LIFECYCLE = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const families_scailo_pb_js_1 = require("./families.scailo_pb.js");
const qc_groups_scailo_pb_js_1 = require("./qc_groups.scailo_pb.js");
const inventory_scailo_pb_js_1 = require("./inventory.scailo_pb.js");
/**
 *
 * Describes the lifecycle status of each qc sample
 *
 * @generated from enum Scailo.QC_SAMPLE_LIFECYCLE
 */
var QC_SAMPLE_LIFECYCLE;
(function (QC_SAMPLE_LIFECYCLE) {
    /**
     * Used only in filters
     *
     * @generated from enum value: QC_SAMPLE_LIFECYCLE_ANY_UNSPECIFIED = 0;
     */
    QC_SAMPLE_LIFECYCLE[QC_SAMPLE_LIFECYCLE["QC_SAMPLE_LIFECYCLE_ANY_UNSPECIFIED"] = 0] = "QC_SAMPLE_LIFECYCLE_ANY_UNSPECIFIED";
    /**
     * Denotes that the qc sample is open
     *
     * @generated from enum value: QC_SAMPLE_LIFECYCLE_OPEN = 1;
     */
    QC_SAMPLE_LIFECYCLE[QC_SAMPLE_LIFECYCLE["QC_SAMPLE_LIFECYCLE_OPEN"] = 1] = "QC_SAMPLE_LIFECYCLE_OPEN";
    /**
     * Denotes that the qc sample has been finished and waiting for approval
     *
     * @generated from enum value: QC_SAMPLE_LIFECYCLE_FINISHED = 2;
     */
    QC_SAMPLE_LIFECYCLE[QC_SAMPLE_LIFECYCLE["QC_SAMPLE_LIFECYCLE_FINISHED"] = 2] = "QC_SAMPLE_LIFECYCLE_FINISHED";
    /**
     * Denotes that the qc sample has been accepted
     *
     * @generated from enum value: QC_SAMPLE_LIFECYCLE_ACCEPTED = 3;
     */
    QC_SAMPLE_LIFECYCLE[QC_SAMPLE_LIFECYCLE["QC_SAMPLE_LIFECYCLE_ACCEPTED"] = 3] = "QC_SAMPLE_LIFECYCLE_ACCEPTED";
    /**
     * Denotes that the qc sample has been accepted with deviation
     *
     * @generated from enum value: QC_SAMPLE_LIFECYCLE_ACCEPTED_WITH_DEVIATION = 4;
     */
    QC_SAMPLE_LIFECYCLE[QC_SAMPLE_LIFECYCLE["QC_SAMPLE_LIFECYCLE_ACCEPTED_WITH_DEVIATION"] = 4] = "QC_SAMPLE_LIFECYCLE_ACCEPTED_WITH_DEVIATION";
    /**
     * Denotes that the qc sample has been rejected
     *
     * @generated from enum value: QC_SAMPLE_LIFECYCLE_REJECTED = 5;
     */
    QC_SAMPLE_LIFECYCLE[QC_SAMPLE_LIFECYCLE["QC_SAMPLE_LIFECYCLE_REJECTED"] = 5] = "QC_SAMPLE_LIFECYCLE_REJECTED";
    /**
     * Denotes that the qc sample has been cancelled
     *
     * @generated from enum value: QC_SAMPLE_LIFECYCLE_CANCELLED = 6;
     */
    QC_SAMPLE_LIFECYCLE[QC_SAMPLE_LIFECYCLE["QC_SAMPLE_LIFECYCLE_CANCELLED"] = 6] = "QC_SAMPLE_LIFECYCLE_CANCELLED";
})(QC_SAMPLE_LIFECYCLE || (exports.QC_SAMPLE_LIFECYCLE = QC_SAMPLE_LIFECYCLE = {}));
// Retrieve enum metadata with: proto3.getEnumType(QC_SAMPLE_LIFECYCLE)
protobuf_1.proto3.util.setEnumType(QC_SAMPLE_LIFECYCLE, "Scailo.QC_SAMPLE_LIFECYCLE", [
    { no: 0, name: "QC_SAMPLE_LIFECYCLE_ANY_UNSPECIFIED" },
    { no: 1, name: "QC_SAMPLE_LIFECYCLE_OPEN" },
    { no: 2, name: "QC_SAMPLE_LIFECYCLE_FINISHED" },
    { no: 3, name: "QC_SAMPLE_LIFECYCLE_ACCEPTED" },
    { no: 4, name: "QC_SAMPLE_LIFECYCLE_ACCEPTED_WITH_DEVIATION" },
    { no: 5, name: "QC_SAMPLE_LIFECYCLE_REJECTED" },
    { no: 6, name: "QC_SAMPLE_LIFECYCLE_CANCELLED" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.QC_SAMPLE_SORT_KEY
 */
var QC_SAMPLE_SORT_KEY;
(function (QC_SAMPLE_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: QC_SAMPLE_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    QC_SAMPLE_SORT_KEY[QC_SAMPLE_SORT_KEY["QC_SAMPLE_SORT_KEY_ID_UNSPECIFIED"] = 0] = "QC_SAMPLE_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: QC_SAMPLE_SORT_KEY_CREATED_AT = 1;
     */
    QC_SAMPLE_SORT_KEY[QC_SAMPLE_SORT_KEY["QC_SAMPLE_SORT_KEY_CREATED_AT"] = 1] = "QC_SAMPLE_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: QC_SAMPLE_SORT_KEY_MODIFIED_AT = 2;
     */
    QC_SAMPLE_SORT_KEY[QC_SAMPLE_SORT_KEY["QC_SAMPLE_SORT_KEY_MODIFIED_AT"] = 2] = "QC_SAMPLE_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: QC_SAMPLE_SORT_KEY_APPROVED_ON = 3;
     */
    QC_SAMPLE_SORT_KEY[QC_SAMPLE_SORT_KEY["QC_SAMPLE_SORT_KEY_APPROVED_ON"] = 3] = "QC_SAMPLE_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: QC_SAMPLE_SORT_KEY_APPROVED_BY = 4;
     */
    QC_SAMPLE_SORT_KEY[QC_SAMPLE_SORT_KEY["QC_SAMPLE_SORT_KEY_APPROVED_BY"] = 4] = "QC_SAMPLE_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: QC_SAMPLE_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    QC_SAMPLE_SORT_KEY[QC_SAMPLE_SORT_KEY["QC_SAMPLE_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "QC_SAMPLE_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the approver's finished on timestamp
     *
     * @generated from enum value: QC_SAMPLE_SORT_KEY_FINISHED_ON = 6;
     */
    QC_SAMPLE_SORT_KEY[QC_SAMPLE_SORT_KEY["QC_SAMPLE_SORT_KEY_FINISHED_ON"] = 6] = "QC_SAMPLE_SORT_KEY_FINISHED_ON";
    /**
     * Fetch ordered results by the qc group ID
     *
     * @generated from enum value: QC_SAMPLE_SORT_KEY_QC_GROUP_ID = 7;
     */
    QC_SAMPLE_SORT_KEY[QC_SAMPLE_SORT_KEY["QC_SAMPLE_SORT_KEY_QC_GROUP_ID"] = 7] = "QC_SAMPLE_SORT_KEY_QC_GROUP_ID";
    /**
     * Fetch ordered results by the name
     *
     * @generated from enum value: QC_SAMPLE_SORT_KEY_NAME = 10;
     */
    QC_SAMPLE_SORT_KEY[QC_SAMPLE_SORT_KEY["QC_SAMPLE_SORT_KEY_NAME"] = 10] = "QC_SAMPLE_SORT_KEY_NAME";
})(QC_SAMPLE_SORT_KEY || (exports.QC_SAMPLE_SORT_KEY = QC_SAMPLE_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(QC_SAMPLE_SORT_KEY)
protobuf_1.proto3.util.setEnumType(QC_SAMPLE_SORT_KEY, "Scailo.QC_SAMPLE_SORT_KEY", [
    { no: 0, name: "QC_SAMPLE_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "QC_SAMPLE_SORT_KEY_CREATED_AT" },
    { no: 2, name: "QC_SAMPLE_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "QC_SAMPLE_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "QC_SAMPLE_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "QC_SAMPLE_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 6, name: "QC_SAMPLE_SORT_KEY_FINISHED_ON" },
    { no: 7, name: "QC_SAMPLE_SORT_KEY_QC_GROUP_ID" },
    { no: 10, name: "QC_SAMPLE_SORT_KEY_NAME" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.QC_SAMPLE_PARAMETER_SORT_KEY
 */
var QC_SAMPLE_PARAMETER_SORT_KEY;
(function (QC_SAMPLE_PARAMETER_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: QC_SAMPLE_PARAMETER_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    QC_SAMPLE_PARAMETER_SORT_KEY[QC_SAMPLE_PARAMETER_SORT_KEY["QC_SAMPLE_PARAMETER_SORT_KEY_ID_UNSPECIFIED"] = 0] = "QC_SAMPLE_PARAMETER_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: QC_SAMPLE_PARAMETER_SORT_KEY_CREATED_AT = 1;
     */
    QC_SAMPLE_PARAMETER_SORT_KEY[QC_SAMPLE_PARAMETER_SORT_KEY["QC_SAMPLE_PARAMETER_SORT_KEY_CREATED_AT"] = 1] = "QC_SAMPLE_PARAMETER_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: QC_SAMPLE_PARAMETER_SORT_KEY_MODIFIED_AT = 2;
     */
    QC_SAMPLE_PARAMETER_SORT_KEY[QC_SAMPLE_PARAMETER_SORT_KEY["QC_SAMPLE_PARAMETER_SORT_KEY_MODIFIED_AT"] = 2] = "QC_SAMPLE_PARAMETER_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by username that checked
     *
     * @generated from enum value: QC_SAMPLE_PARAMETER_SORT_KEY_CHECKED_BY = 10;
     */
    QC_SAMPLE_PARAMETER_SORT_KEY[QC_SAMPLE_PARAMETER_SORT_KEY["QC_SAMPLE_PARAMETER_SORT_KEY_CHECKED_BY"] = 10] = "QC_SAMPLE_PARAMETER_SORT_KEY_CHECKED_BY";
    /**
     * Fetch ordered results by the checked at timestamp
     *
     * @generated from enum value: QC_SAMPLE_PARAMETER_SORT_KEY_CHECKED_AT = 11;
     */
    QC_SAMPLE_PARAMETER_SORT_KEY[QC_SAMPLE_PARAMETER_SORT_KEY["QC_SAMPLE_PARAMETER_SORT_KEY_CHECKED_AT"] = 11] = "QC_SAMPLE_PARAMETER_SORT_KEY_CHECKED_AT";
    /**
     * Fetch ordered results by the qc sample ID
     *
     * @generated from enum value: QC_SAMPLE_PARAMETER_SORT_KEY_QC_SAMPLE_ID = 12;
     */
    QC_SAMPLE_PARAMETER_SORT_KEY[QC_SAMPLE_PARAMETER_SORT_KEY["QC_SAMPLE_PARAMETER_SORT_KEY_QC_SAMPLE_ID"] = 12] = "QC_SAMPLE_PARAMETER_SORT_KEY_QC_SAMPLE_ID";
    /**
     * Fetch ordered results by the qc param ID
     *
     * @generated from enum value: QC_SAMPLE_PARAMETER_SORT_KEY_QC_PARAM_ID = 13;
     */
    QC_SAMPLE_PARAMETER_SORT_KEY[QC_SAMPLE_PARAMETER_SORT_KEY["QC_SAMPLE_PARAMETER_SORT_KEY_QC_PARAM_ID"] = 13] = "QC_SAMPLE_PARAMETER_SORT_KEY_QC_PARAM_ID";
    /**
     * Fetch ordered results by the uom ID
     *
     * @generated from enum value: QC_SAMPLE_PARAMETER_SORT_KEY_UOM_ID = 14;
     */
    QC_SAMPLE_PARAMETER_SORT_KEY[QC_SAMPLE_PARAMETER_SORT_KEY["QC_SAMPLE_PARAMETER_SORT_KEY_UOM_ID"] = 14] = "QC_SAMPLE_PARAMETER_SORT_KEY_UOM_ID";
})(QC_SAMPLE_PARAMETER_SORT_KEY || (exports.QC_SAMPLE_PARAMETER_SORT_KEY = QC_SAMPLE_PARAMETER_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(QC_SAMPLE_PARAMETER_SORT_KEY)
protobuf_1.proto3.util.setEnumType(QC_SAMPLE_PARAMETER_SORT_KEY, "Scailo.QC_SAMPLE_PARAMETER_SORT_KEY", [
    { no: 0, name: "QC_SAMPLE_PARAMETER_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "QC_SAMPLE_PARAMETER_SORT_KEY_CREATED_AT" },
    { no: 2, name: "QC_SAMPLE_PARAMETER_SORT_KEY_MODIFIED_AT" },
    { no: 10, name: "QC_SAMPLE_PARAMETER_SORT_KEY_CHECKED_BY" },
    { no: 11, name: "QC_SAMPLE_PARAMETER_SORT_KEY_CHECKED_AT" },
    { no: 12, name: "QC_SAMPLE_PARAMETER_SORT_KEY_QC_SAMPLE_ID" },
    { no: 13, name: "QC_SAMPLE_PARAMETER_SORT_KEY_QC_PARAM_ID" },
    { no: 14, name: "QC_SAMPLE_PARAMETER_SORT_KEY_UOM_ID" },
]);
/**
 *
 * Describes each parameter that's part of the logbook (does not include historical data of the object) with the operation being a qc sample lifecycle status
 *
 * @generated from message Scailo.LogbookLogQCSampleLC
 */
class LogbookLogQCSampleLC extends protobuf_1.Message {
    /**
     * ID of the resource
     *
     * @generated from field: uint64 id = 1;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Stores if the resource is active
     *
     * @generated from field: bool is_active = 2;
     */
    isActive = false;
    /**
     * Stores the timestamp of when this resource was created
     *
     * @generated from field: uint64 timestamp = 3;
     */
    timestamp = protobuf_1.protoInt64.zero;
    /**
     * The UUID of the resource
     *
     * @generated from field: string ref_uuid = 10;
     */
    refUuid = "";
    /**
     * The operation that was performed
     *
     * @generated from field: Scailo.QC_SAMPLE_LIFECYCLE operation = 11;
     */
    operation = QC_SAMPLE_LIFECYCLE.QC_SAMPLE_LIFECYCLE_ANY_UNSPECIFIED;
    /**
     * The username of the user who performed this operation
     *
     * @generated from field: string username = 12;
     */
    username = "";
    /**
     * The name of the person who made this entry
     *
     * @generated from field: string name = 13;
     */
    name = "";
    /**
     * The ID of the user who made this entry
     *
     * @generated from field: uint64 user_id = 14;
     */
    userId = protobuf_1.protoInt64.zero;
    /**
     * The comment generated by the application
     *
     * @generated from field: string app_comment = 15;
     */
    appComment = "";
    /**
     * The comment entered by the user
     *
     * @generated from field: string user_comment = 16;
     */
    userComment = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.LogbookLogQCSampleLC";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 3, name: "timestamp", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "ref_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "operation", kind: "enum", T: protobuf_1.proto3.getEnumType(QC_SAMPLE_LIFECYCLE) },
        { no: 12, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "app_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new LogbookLogQCSampleLC().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new LogbookLogQCSampleLC().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new LogbookLogQCSampleLC().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(LogbookLogQCSampleLC, a, b);
    }
}
exports.LogbookLogQCSampleLC = LogbookLogQCSampleLC;
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.QCSamplesServiceCreateRequest
 */
class QCSamplesServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 8;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The associated family ID
     *
     * @generated from field: uint64 family_id = 10;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The UUID of the inventory item
     *
     * @generated from field: string inventory_item_uuid = 11;
     */
    inventoryItemUuid = "";
    /**
     * The ID of the qc group
     *
     * @generated from field: uint64 qc_group_id = 12;
     */
    qcGroupId = protobuf_1.protoInt64.zero;
    /**
     * The location ID of where the record is created
     *
     * @generated from field: uint64 location_id = 13;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The name that will be used as a prefix
     *
     * @generated from field: string name_prefix = 20;
     */
    namePrefix = "";
    /**
     * The description of the qc sample
     *
     * @generated from field: string description = 21;
     */
    description = "";
    /**
     * The number of samples that need to be created
     *
     * @generated from field: uint64 sample_count = 30;
     */
    sampleCount = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QCSamplesServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 8, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "inventory_item_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "qc_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "name_prefix", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "sample_count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new QCSamplesServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QCSamplesServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QCSamplesServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QCSamplesServiceCreateRequest, a, b);
    }
}
exports.QCSamplesServiceCreateRequest = QCSamplesServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.QCSamplesServiceUpdateRequest
 */
class QCSamplesServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 8;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The name of the qc sample
     *
     * @generated from field: string name = 20;
     */
    name = "";
    /**
     * The description of the qc sample
     *
     * @generated from field: string description = 21;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QCSamplesServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 8, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new QCSamplesServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QCSamplesServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QCSamplesServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QCSamplesServiceUpdateRequest, a, b);
    }
}
exports.QCSamplesServiceUpdateRequest = QCSamplesServiceUpdateRequest;
/**
 *
 * Stores the UUID references of the record
 *
 * @generated from message Scailo.QCSampleAncillaryParameters
 */
class QCSampleAncillaryParameters extends protobuf_1.Message {
    /**
     * The UUID of the family
     *
     * @generated from field: string family_uuid = 100;
     */
    familyUuid = "";
    /**
     * The UUID of the inventory item
     *
     * @generated from field: string inventory_item_uuid = 111;
     */
    inventoryItemUuid = "";
    /**
     * The UUID of the qc group
     *
     * @generated from field: string qc_group_uuid = 112;
     */
    qcGroupUuid = "";
    /**
     * The UUID of the location
     *
     * @generated from field: string location_uuid = 113;
     */
    locationUuid = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QCSampleAncillaryParameters";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 100, name: "family_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 111, name: "inventory_item_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 112, name: "qc_group_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 113, name: "location_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new QCSampleAncillaryParameters().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QCSampleAncillaryParameters().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QCSampleAncillaryParameters().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QCSampleAncillaryParameters, a, b);
    }
}
exports.QCSampleAncillaryParameters = QCSampleAncillaryParameters;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.QCSample
 */
class QCSample extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this qc sample
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this qc sample
     *
     * @generated from field: Scailo.QC_SAMPLE_LIFECYCLE status = 4;
     */
    status = QC_SAMPLE_LIFECYCLE.QC_SAMPLE_LIFECYCLE_ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this qc sample
     *
     * @generated from field: repeated Scailo.LogbookLogQCSampleLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this qc sample was marked as finished
     *
     * @generated from field: uint64 finished_on = 6;
     */
    finishedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 8;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The associated family type
     *
     * @generated from field: Scailo.FAMILY_TYPE family_type = 9;
     */
    familyType = families_scailo_pb_js_1.FAMILY_TYPE.FAMILY_TYPE_ANY_UNSPECIFIED;
    /**
     * The associated family ID
     *
     * @generated from field: uint64 family_id = 10;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The UUID of the inventory item
     *
     * @generated from field: string inventory_item_uuid = 11;
     */
    inventoryItemUuid = "";
    /**
     * The ID of the qc group
     *
     * @generated from field: uint64 qc_group_id = 12;
     */
    qcGroupId = protobuf_1.protoInt64.zero;
    /**
     * The location ID of where the record is created
     *
     * @generated from field: uint64 location_id = 13;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The name of the qc sample (will be autogenerated if not provided)
     *
     * @generated from field: string name = 20;
     */
    name = "";
    /**
     * The description of the qc sample
     *
     * @generated from field: string description = 21;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QCSample";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(QC_SAMPLE_LIFECYCLE) },
        { no: 5, name: "logs", kind: "message", T: LogbookLogQCSampleLC, repeated: true },
        { no: 6, name: "finished_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "family_type", kind: "enum", T: protobuf_1.proto3.getEnumType(families_scailo_pb_js_1.FAMILY_TYPE) },
        { no: 10, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "inventory_item_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "qc_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new QCSample().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QCSample().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QCSample().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QCSample, a, b);
    }
}
exports.QCSample = QCSample;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.QCSampleWithMetadata
 */
class QCSampleWithMetadata extends protobuf_1.Message {
    /**
     * Stores the QC Sample
     *
     * @generated from field: Scailo.QCSample qc_sample = 1;
     */
    qcSample;
    /**
     * Stores the family information
     *
     * @generated from field: Scailo.Family family = 10;
     */
    family;
    /**
     * Stores the associated qc group
     *
     * @generated from field: Scailo.QCGroup qc_group = 20;
     */
    qcGroup;
    /**
     * Stores the generic inventory information
     *
     * @generated from field: Scailo.GenericInventory inventory_item = 30;
     */
    inventoryItem;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QCSampleWithMetadata";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "qc_sample", kind: "message", T: QCSample },
        { no: 10, name: "family", kind: "message", T: families_scailo_pb_js_1.Family },
        { no: 20, name: "qc_group", kind: "message", T: qc_groups_scailo_pb_js_1.QCGroup },
        { no: 30, name: "inventory_item", kind: "message", T: inventory_scailo_pb_js_1.GenericInventory },
    ]);
    static fromBinary(bytes, options) {
        return new QCSampleWithMetadata().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QCSampleWithMetadata().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QCSampleWithMetadata().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QCSampleWithMetadata, a, b);
    }
}
exports.QCSampleWithMetadata = QCSampleWithMetadata;
/**
 *
 * Describes the message consisting of the list of records
 *
 * @generated from message Scailo.QCSamplesList
 */
class QCSamplesList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.QCSample list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QCSamplesList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: QCSample, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new QCSamplesList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QCSamplesList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QCSamplesList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QCSamplesList, a, b);
    }
}
exports.QCSamplesList = QCSamplesList;
/**
 *
 * Describes the message consisting of the list of QC samples with metadata
 *
 * @generated from message Scailo.QCSamplesWithMetadataList
 */
class QCSamplesWithMetadataList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.QCSampleWithMetadata list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QCSamplesWithMetadataList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: QCSampleWithMetadata, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new QCSamplesWithMetadataList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QCSamplesWithMetadataList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QCSamplesWithMetadataList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QCSamplesWithMetadataList, a, b);
    }
}
exports.QCSamplesWithMetadataList = QCSamplesWithMetadataList;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.QCSamplesServicePaginationReq
 */
class QCSamplesServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.QC_SAMPLE_SORT_KEY sort_key = 5;
     */
    sortKey = QC_SAMPLE_SORT_KEY.QC_SAMPLE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this qc sample
     *
     * @generated from field: Scailo.QC_SAMPLE_LIFECYCLE status = 6;
     */
    status = QC_SAMPLE_LIFECYCLE.QC_SAMPLE_LIFECYCLE_ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QCSamplesServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(QC_SAMPLE_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(QC_SAMPLE_LIFECYCLE) },
    ]);
    static fromBinary(bytes, options) {
        return new QCSamplesServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QCSamplesServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QCSamplesServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QCSamplesServicePaginationReq, a, b);
    }
}
exports.QCSamplesServicePaginationReq = QCSamplesServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.QCSamplesServicePaginationResponse
 */
class QCSamplesServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.QCSample payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QCSamplesServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: QCSample, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new QCSamplesServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QCSamplesServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QCSamplesServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QCSamplesServicePaginationResponse, a, b);
    }
}
exports.QCSamplesServicePaginationResponse = QCSamplesServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.QCSamplesServiceFilterReq
 */
class QCSamplesServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.QC_SAMPLE_SORT_KEY sort_key = 5;
     */
    sortKey = QC_SAMPLE_SORT_KEY.QC_SAMPLE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this qc sample
     *
     * @generated from field: Scailo.QC_SAMPLE_LIFECYCLE status = 10;
     */
    status = QC_SAMPLE_LIFECYCLE.QC_SAMPLE_LIFECYCLE_ANY_UNSPECIFIED;
    /**
     * The start range of finished timestamp
     *
     * @generated from field: uint64 finished_on_start = 15;
     */
    finishedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of finished timestamp
     *
     * @generated from field: uint64 finished_on_end = 16;
     */
    finishedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The associated family type
     *
     * @generated from field: Scailo.FAMILY_TYPE family_type = 17;
     */
    familyType = families_scailo_pb_js_1.FAMILY_TYPE.FAMILY_TYPE_ANY_UNSPECIFIED;
    /**
     * The associated family ID
     *
     * @generated from field: uint64 family_id = 18;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the qc group
     *
     * @generated from field: uint64 qc_group_id = 19;
     */
    qcGroupId = protobuf_1.protoInt64.zero;
    /**
     * The UUID of the inventory item
     *
     * @generated from field: string inventory_item_uuid = 20;
     */
    inventoryItemUuid = "";
    /**
     * The location ID of where the record is created
     *
     * @generated from field: uint64 location_id = 21;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * ------------------------------------------------------------
     * Higher order filters
     * ------------------------------------------------------------
     * The associated production plan ID
     *
     * @generated from field: uint64 production_plan_id = 50;
     */
    productionPlanId = protobuf_1.protoInt64.zero;
    /**
     * The associated goods receipt ID
     *
     * @generated from field: uint64 goods_receipt_id = 51;
     */
    goodsReceiptId = protobuf_1.protoInt64.zero;
    /**
     * The associated inward job free issue material ID
     *
     * @generated from field: uint64 inward_job_free_issue_material_id = 52;
     */
    inwardJobFreeIssueMaterialId = protobuf_1.protoInt64.zero;
    /**
     * The associated vendor ID
     *
     * @generated from field: uint64 vendor_id = 55;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * The associated buyer client ID of the linked sales order
     *
     * ------------------------------------------------------------
     *
     * @generated from field: uint64 buyer_client_id = 70;
     */
    buyerClientId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QCSamplesServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(QC_SAMPLE_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(QC_SAMPLE_LIFECYCLE) },
        { no: 15, name: "finished_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "finished_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "family_type", kind: "enum", T: protobuf_1.proto3.getEnumType(families_scailo_pb_js_1.FAMILY_TYPE) },
        { no: 18, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 19, name: "qc_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "inventory_item_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 50, name: "production_plan_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 51, name: "goods_receipt_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 52, name: "inward_job_free_issue_material_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 55, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 70, name: "buyer_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new QCSamplesServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QCSamplesServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QCSamplesServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QCSamplesServiceFilterReq, a, b);
    }
}
exports.QCSamplesServiceFilterReq = QCSamplesServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.QCSamplesServiceCountReq
 */
class QCSamplesServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this qc sample
     *
     * @generated from field: Scailo.QC_SAMPLE_LIFECYCLE status = 10;
     */
    status = QC_SAMPLE_LIFECYCLE.QC_SAMPLE_LIFECYCLE_ANY_UNSPECIFIED;
    /**
     * The start range of finished timestamp
     *
     * @generated from field: uint64 finished_on_start = 15;
     */
    finishedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of finished timestamp
     *
     * @generated from field: uint64 finished_on_end = 16;
     */
    finishedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The associated family type
     *
     * @generated from field: Scailo.FAMILY_TYPE family_type = 17;
     */
    familyType = families_scailo_pb_js_1.FAMILY_TYPE.FAMILY_TYPE_ANY_UNSPECIFIED;
    /**
     * The associated family ID
     *
     * @generated from field: uint64 family_id = 18;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the qc group
     *
     * @generated from field: uint64 qc_group_id = 19;
     */
    qcGroupId = protobuf_1.protoInt64.zero;
    /**
     * The UUID of the inventory item
     *
     * @generated from field: string inventory_item_uuid = 20;
     */
    inventoryItemUuid = "";
    /**
     * The location ID of where the record is created
     *
     * @generated from field: uint64 location_id = 21;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * ------------------------------------------------------------
     * Higher order filters
     * ------------------------------------------------------------
     * The associated production plan ID
     *
     * @generated from field: uint64 production_plan_id = 50;
     */
    productionPlanId = protobuf_1.protoInt64.zero;
    /**
     * The associated goods receipt ID
     *
     * @generated from field: uint64 goods_receipt_id = 51;
     */
    goodsReceiptId = protobuf_1.protoInt64.zero;
    /**
     * The associated inward job free issue material ID
     *
     * @generated from field: uint64 inward_job_free_issue_material_id = 52;
     */
    inwardJobFreeIssueMaterialId = protobuf_1.protoInt64.zero;
    /**
     * The associated vendor ID
     *
     * @generated from field: uint64 vendor_id = 55;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * The associated buyer client ID of the linked sales order
     *
     * ------------------------------------------------------------
     *
     * @generated from field: uint64 buyer_client_id = 70;
     */
    buyerClientId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QCSamplesServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(QC_SAMPLE_LIFECYCLE) },
        { no: 15, name: "finished_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "finished_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "family_type", kind: "enum", T: protobuf_1.proto3.getEnumType(families_scailo_pb_js_1.FAMILY_TYPE) },
        { no: 18, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 19, name: "qc_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "inventory_item_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 50, name: "production_plan_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 51, name: "goods_receipt_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 52, name: "inward_job_free_issue_material_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 55, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 70, name: "buyer_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new QCSamplesServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QCSamplesServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QCSamplesServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QCSamplesServiceCountReq, a, b);
    }
}
exports.QCSamplesServiceCountReq = QCSamplesServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.QCSamplesServiceSearchAllReq
 */
class QCSamplesServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.QC_SAMPLE_SORT_KEY sort_key = 5;
     */
    sortKey = QC_SAMPLE_SORT_KEY.QC_SAMPLE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.QC_SAMPLE_LIFECYCLE status = 10;
     */
    status = QC_SAMPLE_LIFECYCLE.QC_SAMPLE_LIFECYCLE_ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    /**
     * The associated family type
     *
     * @generated from field: Scailo.FAMILY_TYPE family_type = 17;
     */
    familyType = families_scailo_pb_js_1.FAMILY_TYPE.FAMILY_TYPE_ANY_UNSPECIFIED;
    /**
     * The associated family ID
     *
     * @generated from field: uint64 family_id = 18;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the qc group
     *
     * @generated from field: uint64 qc_group_id = 19;
     */
    qcGroupId = protobuf_1.protoInt64.zero;
    /**
     * The UUID of the inventory item
     *
     * @generated from field: string inventory_item_uuid = 20;
     */
    inventoryItemUuid = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QCSamplesServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(QC_SAMPLE_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(QC_SAMPLE_LIFECYCLE) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 17, name: "family_type", kind: "enum", T: protobuf_1.proto3.getEnumType(families_scailo_pb_js_1.FAMILY_TYPE) },
        { no: 18, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 19, name: "qc_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "inventory_item_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new QCSamplesServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QCSamplesServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QCSamplesServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QCSamplesServiceSearchAllReq, a, b);
    }
}
exports.QCSamplesServiceSearchAllReq = QCSamplesServiceSearchAllReq;
/**
 *
 * Describes the parameters required to update an parameter in a qc sample
 *
 * @generated from message Scailo.QCSamplesServiceParameterUpdateRequest
 */
class QCSamplesServiceParameterUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Stores the observed value when it is a number
     *
     * @generated from field: int64 number_observed_value = 13;
     */
    numberObservedValue = protobuf_1.protoInt64.zero;
    /**
     * Stores the observed value when it is a string
     *
     * @generated from field: string text_observed_value = 14;
     */
    textObservedValue = "";
    /**
     * An optional description
     *
     * @generated from field: string description = 20;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QCSamplesServiceParameterUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "number_observed_value", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 14, name: "text_observed_value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new QCSamplesServiceParameterUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QCSamplesServiceParameterUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QCSamplesServiceParameterUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QCSamplesServiceParameterUpdateRequest, a, b);
    }
}
exports.QCSamplesServiceParameterUpdateRequest = QCSamplesServiceParameterUpdateRequest;
/**
 *
 * Describes the parameters that constitute an parameter associated to a qc sample
 *
 * @generated from message Scailo.QCSampleParameter
 */
class QCSampleParameter extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this qc sample
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the username of the employee who performed the check
     *
     * @generated from field: string checked_by = 8;
     */
    checkedBy = "";
    /**
     * Stores the timestamp of when this check was performed
     *
     * @generated from field: uint64 checked_at = 9;
     */
    checkedAt = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the qc sample
     *
     * @generated from field: uint64 qc_sample_id = 10;
     */
    qcSampleId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the qc param that is a part of the qc sample
     *
     * @generated from field: uint64 qc_param_id = 11;
     */
    qcParamId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated unit of material
     *
     * @generated from field: uint64 uom_id = 12;
     */
    uomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the observed value when it is a number
     *
     * @generated from field: int64 number_observed_value = 13;
     */
    numberObservedValue = protobuf_1.protoInt64.zero;
    /**
     * Stores the observed value when it is a string
     *
     * @generated from field: string text_observed_value = 14;
     */
    textObservedValue = "";
    /**
     * Denotes if this parameter is internal or not (if set to true, then this value will be hidden. If set to false, then this value will be printed)
     *
     * @generated from field: bool is_internal = 19;
     */
    isInternal = false;
    /**
     * The acceptable value type of the item
     *
     * @generated from field: Scailo.QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE acceptable_value_type = 20;
     */
    acceptableValueType = qc_groups_scailo_pb_js_1.QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE.QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE_ANY_UNSPECIFIED;
    /**
     * The acceptable value (in cents) in case the acceptable value type is number-absolute or number-percentage
     *
     * @generated from field: int64 number_acceptable_value = 21;
     */
    numberAcceptableValue = protobuf_1.protoInt64.zero;
    /**
     * The relative lower bound (in cents) of the number_acceptable_value that serves as a reference point for the test
     *
     * @generated from field: int64 number_relative_lower_bound = 22;
     */
    numberRelativeLowerBound = protobuf_1.protoInt64.zero;
    /**
     * The relative upper bound (in cents) of the number_acceptable_value that serves as a reference point for the test
     *
     * @generated from field: int64 number_relative_upper_bound = 23;
     */
    numberRelativeUpperBound = protobuf_1.protoInt64.zero;
    /**
     * The list of values that are acceptable in case the acceptable value type is text-input or text-dropdown
     *
     * @generated from field: repeated string text_acceptable_values = 30;
     */
    textAcceptableValues = [];
    /**
     * The list of values that are acceptable (but with deviation) in case the acceptable value type is text-input or text-dropdown
     *
     * @generated from field: repeated string text_acceptable_values_with_deviation = 31;
     */
    textAcceptableValuesWithDeviation = [];
    /**
     * The list of unacceptable values in case the acceptable value type is text-input or text-dropdown
     *
     * @generated from field: repeated string text_unacceptable_values = 32;
     */
    textUnacceptableValues = [];
    /**
     * An optional description
     *
     * @generated from field: string description = 40;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QCSampleParameter";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 8, name: "checked_by", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "checked_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "qc_sample_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "qc_param_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "number_observed_value", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 14, name: "text_observed_value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 19, name: "is_internal", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 20, name: "acceptable_value_type", kind: "enum", T: protobuf_1.proto3.getEnumType(qc_groups_scailo_pb_js_1.QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE) },
        { no: 21, name: "number_acceptable_value", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 22, name: "number_relative_lower_bound", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 23, name: "number_relative_upper_bound", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 30, name: "text_acceptable_values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
        { no: 31, name: "text_acceptable_values_with_deviation", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
        { no: 32, name: "text_unacceptable_values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
        { no: 40, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new QCSampleParameter().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QCSampleParameter().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QCSampleParameter().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QCSampleParameter, a, b);
    }
}
exports.QCSampleParameter = QCSampleParameter;
/**
 *
 * Describes the message consisting of the list of qc sample parameters
 *
 * @generated from message Scailo.QCSampleParametersList
 */
class QCSampleParametersList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.QCSampleParameter list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QCSampleParametersList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: QCSampleParameter, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new QCSampleParametersList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QCSampleParametersList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QCSampleParametersList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QCSampleParametersList, a, b);
    }
}
exports.QCSampleParametersList = QCSampleParametersList;
/**
 *
 * Describes the parameters that are required to retrieve the history of the record
 *
 * @generated from message Scailo.QCSampleParameterHistoryRequest
 */
class QCSampleParameterHistoryRequest extends protobuf_1.Message {
    /**
     * Stores the qc sample parameter ID
     *
     * @generated from field: uint64 qc_sample_parameter_id = 10;
     */
    qcSampleParameterId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QCSampleParameterHistoryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "qc_sample_parameter_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new QCSampleParameterHistoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QCSampleParameterHistoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QCSampleParameterHistoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QCSampleParameterHistoryRequest, a, b);
    }
}
exports.QCSampleParameterHistoryRequest = QCSampleParameterHistoryRequest;
/**
 *
 * Describes the parameters that are present in a history response
 *
 * @generated from message Scailo.QCSampleParameterHistory
 */
class QCSampleParameterHistory extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this qc sample
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the username of the employee who performed the check
     *
     * @generated from field: string checked_by = 8;
     */
    checkedBy = "";
    /**
     * Stores the timestamp of when this check was performed
     *
     * @generated from field: uint64 checked_at = 9;
     */
    checkedAt = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the qc sample parameter
     *
     * @generated from field: uint64 qc_sample_parameter_id = 10;
     */
    qcSampleParameterId = protobuf_1.protoInt64.zero;
    /**
     * Stores the observed value when it is a number
     *
     * @generated from field: int64 number_observed_value = 13;
     */
    numberObservedValue = protobuf_1.protoInt64.zero;
    /**
     * Stores the observed value when it is a string
     *
     * @generated from field: string text_observed_value = 14;
     */
    textObservedValue = "";
    /**
     * An optional description
     *
     * @generated from field: string description = 40;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QCSampleParameterHistory";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 8, name: "checked_by", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "checked_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "qc_sample_parameter_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "number_observed_value", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 14, name: "text_observed_value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 40, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new QCSampleParameterHistory().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QCSampleParameterHistory().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QCSampleParameterHistory().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QCSampleParameterHistory, a, b);
    }
}
exports.QCSampleParameterHistory = QCSampleParameterHistory;
/**
 *
 * Describes the list of history parameters
 *
 * @generated from message Scailo.QCSampleParameterHistoryList
 */
class QCSampleParameterHistoryList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.QCSampleParameterHistory list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QCSampleParameterHistoryList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: QCSampleParameterHistory, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new QCSampleParameterHistoryList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QCSampleParameterHistoryList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QCSampleParameterHistoryList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QCSampleParameterHistoryList, a, b);
    }
}
exports.QCSampleParameterHistoryList = QCSampleParameterHistoryList;
/**
 *
 * Describes the request payload to retrieve parameters.
 *
 * @generated from message Scailo.QCSampleParametersSearchRequest
 */
class QCSampleParametersSearchRequest extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.QC_SAMPLE_PARAMETER_SORT_KEY sort_key = 5;
     */
    sortKey = QC_SAMPLE_PARAMETER_SORT_KEY.QC_SAMPLE_PARAMETER_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Stores the username of the employee who performed the check
     *
     * @generated from field: string checked_by = 10;
     */
    checkedBy = "";
    /**
     * The start range of checked timestamp
     *
     * @generated from field: uint64 checked_at_start = 11;
     */
    checkedAtStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of checked timestamp
     *
     * @generated from field: uint64 checked_at_end = 12;
     */
    checkedAtEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the qc sample
     *
     * @generated from field: uint64 qc_sample_id = 13;
     */
    qcSampleId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the qc param that is a part of the qc sample
     *
     * @generated from field: uint64 qc_param_id = 14;
     */
    qcParamId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated unit of material
     *
     * @generated from field: uint64 uom_id = 15;
     */
    uomId = protobuf_1.protoInt64.zero;
    /**
     * The start range of number observed value
     *
     * @generated from field: int64 number_observed_value_start = 20;
     */
    numberObservedValueStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of number observed value
     *
     * @generated from field: int64 number_observed_value_end = 21;
     */
    numberObservedValueEnd = protobuf_1.protoInt64.zero;
    /**
     * The text observed value
     *
     * @generated from field: string text_observed_value = 22;
     */
    textObservedValue = "";
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 40;
     */
    searchKey = "";
    /**
     * ------------------------------------------------------------
     * Higher order filters
     * ------------------------------------------------------------
     * The associated production plan ID
     *
     * @generated from field: uint64 production_plan_id = 50;
     */
    productionPlanId = protobuf_1.protoInt64.zero;
    /**
     * The associated goods receipt ID
     *
     * @generated from field: uint64 goods_receipt_id = 51;
     */
    goodsReceiptId = protobuf_1.protoInt64.zero;
    /**
     * The associated inward job free issue material ID
     *
     * @generated from field: uint64 inward_job_free_issue_material_id = 52;
     */
    inwardJobFreeIssueMaterialId = protobuf_1.protoInt64.zero;
    /**
     * The associated vendor ID
     *
     * @generated from field: uint64 vendor_id = 55;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * The associated family ID
     *
     * ------------------------------------------------------------
     *
     * @generated from field: uint64 family_id = 60;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QCSampleParametersSearchRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(QC_SAMPLE_PARAMETER_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "checked_by", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "checked_at_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "checked_at_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "qc_sample_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "qc_param_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "number_observed_value_start", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 21, name: "number_observed_value_end", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 22, name: "text_observed_value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 40, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 50, name: "production_plan_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 51, name: "goods_receipt_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 52, name: "inward_job_free_issue_material_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 55, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 60, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new QCSampleParametersSearchRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QCSampleParametersSearchRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QCSampleParametersSearchRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QCSampleParametersSearchRequest, a, b);
    }
}
exports.QCSampleParametersSearchRequest = QCSampleParametersSearchRequest;
/**
 *
 * Describes the response to a pagination parameters request
 *
 * @generated from message Scailo.QCSamplesServicePaginatedParametersResponse
 */
class QCSamplesServicePaginatedParametersResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.QCSampleParameter payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QCSamplesServicePaginatedParametersResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: QCSampleParameter, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new QCSamplesServicePaginatedParametersResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QCSamplesServicePaginatedParametersResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QCSamplesServicePaginatedParametersResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QCSamplesServicePaginatedParametersResponse, a, b);
    }
}
exports.QCSamplesServicePaginatedParametersResponse = QCSamplesServicePaginatedParametersResponse;
