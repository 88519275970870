"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file proforma_invoices.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProformaInvoicesService = void 0;
const proforma_invoices_scailo_pb_js_1 = require("./proforma_invoices.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
const families_scailo_pb_js_1 = require("./families.scailo_pb.js");
/**
 *
 * Describes the common methods applicable on each proforma invoice
 *
 * @generated from service Scailo.ProformaInvoicesService
 */
exports.ProformaInvoicesService = {
    typeName: "Scailo.ProformaInvoicesService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.ProformaInvoicesService.Create
         */
        create: {
            name: "Create",
            I: proforma_invoices_scailo_pb_js_1.ProformaInvoicesServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.ProformaInvoicesService.Draft
         */
        draft: {
            name: "Draft",
            I: proforma_invoices_scailo_pb_js_1.ProformaInvoicesServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.ProformaInvoicesService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: proforma_invoices_scailo_pb_js_1.ProformaInvoicesServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.ProformaInvoicesService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.ProformaInvoicesService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.ProformaInvoicesService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.ProformaInvoicesService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.ProformaInvoicesService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: proforma_invoices_scailo_pb_js_1.ProformaInvoicesServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.ProformaInvoicesService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.ProformaInvoicesService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.ProformaInvoicesService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.ProformaInvoicesService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.ProformaInvoicesService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.ProformaInvoicesService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * @generated from rpc Scailo.ProformaInvoicesService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send Email
         *
         * @generated from rpc Scailo.ProformaInvoicesService.SendEmail
         */
        sendEmail: {
            name: "SendEmail",
            I: base_scailo_pb_js_1.IdentifierWithEmailAttributes,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Autofill the proforma invoice
         *
         * @generated from rpc Scailo.ProformaInvoicesService.Autofill
         */
        autofill: {
            name: "Autofill",
            I: proforma_invoices_scailo_pb_js_1.ProformaInvoicesServiceAutofillRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Amend the proforma invoice and send for revision
         *
         * @generated from rpc Scailo.ProformaInvoicesService.Amend
         */
        amend: {
            name: "Amend",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an item to a proforma invoice
         *
         * @generated from rpc Scailo.ProformaInvoicesService.AddProformaInvoiceItem
         */
        addProformaInvoiceItem: {
            name: "AddProformaInvoiceItem",
            I: proforma_invoices_scailo_pb_js_1.ProformaInvoicesServiceItemCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify an item in a proforma invoice
         *
         * @generated from rpc Scailo.ProformaInvoicesService.ModifyProformaInvoiceItem
         */
        modifyProformaInvoiceItem: {
            name: "ModifyProformaInvoiceItem",
            I: proforma_invoices_scailo_pb_js_1.ProformaInvoicesServiceItemUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve an item in a proforma invoice
         *
         * @generated from rpc Scailo.ProformaInvoicesService.ApproveProformaInvoiceItem
         */
        approveProformaInvoiceItem: {
            name: "ApproveProformaInvoiceItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an item in a proforma invoice
         *
         * @generated from rpc Scailo.ProformaInvoicesService.DeleteProformaInvoiceItem
         */
        deleteProformaInvoiceItem: {
            name: "DeleteProformaInvoiceItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder items in a proforma invoice
         *
         * @generated from rpc Scailo.ProformaInvoicesService.ReorderProformaInvoiceItems
         */
        reorderProformaInvoiceItems: {
            name: "ReorderProformaInvoiceItems",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Proforma Invoice Item by ID
         *
         * @generated from rpc Scailo.ProformaInvoicesService.ViewProformaInvoiceItemByID
         */
        viewProformaInvoiceItemByID: {
            name: "ViewProformaInvoiceItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: proforma_invoices_scailo_pb_js_1.ProformaInvoiceItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved proforma invoice items for given proforma invoice ID
         *
         * @generated from rpc Scailo.ProformaInvoicesService.ViewApprovedProformaInvoiceItems
         */
        viewApprovedProformaInvoiceItems: {
            name: "ViewApprovedProformaInvoiceItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: proforma_invoices_scailo_pb_js_1.ProformaInvoiceItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved proforma invoice items for given proforma invoice ID
         *
         * @generated from rpc Scailo.ProformaInvoicesService.ViewUnapprovedProformaInvoiceItems
         */
        viewUnapprovedProformaInvoiceItems: {
            name: "ViewUnapprovedProformaInvoiceItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: proforma_invoices_scailo_pb_js_1.ProformaInvoiceItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the proforma invoice item
         *
         * @generated from rpc Scailo.ProformaInvoicesService.ViewProformaInvoiceItemHistory
         */
        viewProformaInvoiceItemHistory: {
            name: "ViewProformaInvoiceItemHistory",
            I: proforma_invoices_scailo_pb_js_1.ProformaInvoiceItemHistoryRequest,
            O: proforma_invoices_scailo_pb_js_1.ProformaInvoiceItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved proforma invoice items for given proforma invoice ID with pagination
         *
         * @generated from rpc Scailo.ProformaInvoicesService.ViewPaginatedApprovedProformaInvoiceItems
         */
        viewPaginatedApprovedProformaInvoiceItems: {
            name: "ViewPaginatedApprovedProformaInvoiceItems",
            I: proforma_invoices_scailo_pb_js_1.ProformaInvoiceItemsSearchRequest,
            O: proforma_invoices_scailo_pb_js_1.ProformaInvoicesServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved proforma invoice items for given proforma invoice ID with pagination
         *
         * @generated from rpc Scailo.ProformaInvoicesService.ViewPaginatedUnapprovedProformaInvoiceItems
         */
        viewPaginatedUnapprovedProformaInvoiceItems: {
            name: "ViewPaginatedUnapprovedProformaInvoiceItems",
            I: proforma_invoices_scailo_pb_js_1.ProformaInvoiceItemsSearchRequest,
            O: proforma_invoices_scailo_pb_js_1.ProformaInvoicesServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through proforma invoice items with pagination
         *
         * @generated from rpc Scailo.ProformaInvoicesService.SearchItemsWithPagination
         */
        searchItemsWithPagination: {
            name: "SearchItemsWithPagination",
            I: proforma_invoices_scailo_pb_js_1.ProformaInvoiceItemsSearchRequest,
            O: proforma_invoices_scailo_pb_js_1.ProformaInvoicesServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * CSV operations
         * Download the CSV file that could be used to upload items from the filled CSV file. The same file can also be used as a template when there are no existing records
         *
         * @generated from rpc Scailo.ProformaInvoicesService.DownloadItemsAsCSV
         */
        downloadItemsAsCSV: {
            name: "DownloadItemsAsCSV",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Upload items using a CSV file. This is an idempotent operation. All the existing items are deleted before adding the items from the file.
         *
         * @generated from rpc Scailo.ProformaInvoicesService.UploadProformaInvoiceItems
         */
        uploadProformaInvoiceItems: {
            name: "UploadProformaInvoiceItems",
            I: base_scailo_pb_js_1.IdentifierUUIDWithFile,
            O: base_scailo_pb_js_1.IdentifiersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.ProformaInvoicesService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: proforma_invoices_scailo_pb_js_1.ProformaInvoice,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID
         *
         * @generated from rpc Scailo.ProformaInvoicesService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: proforma_invoices_scailo_pb_js_1.ProformaInvoice,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the ancillary parameters (UUIDs of the internal references) by UUID
         *
         * @generated from rpc Scailo.ProformaInvoicesService.ViewAncillaryParametersByUUID
         */
        viewAncillaryParametersByUUID: {
            name: "ViewAncillaryParametersByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: proforma_invoices_scailo_pb_js_1.ProformaInvoiceAncillaryParameters,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.ProformaInvoicesService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: proforma_invoices_scailo_pb_js_1.ProformaInvoicesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.ProformaInvoicesService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: proforma_invoices_scailo_pb_js_1.ProformaInvoicesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.ProformaInvoicesService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: proforma_invoices_scailo_pb_js_1.ProformaInvoicesServicePaginationReq,
            O: proforma_invoices_scailo_pb_js_1.ProformaInvoicesServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all the amendments made
         *
         * @generated from rpc Scailo.ProformaInvoicesService.ViewAmendments
         */
        viewAmendments: {
            name: "ViewAmendments",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.AmendmentLogsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective families for the given proforma invoice
         *
         * @generated from rpc Scailo.ProformaInvoicesService.ViewProspectiveFamilies
         */
        viewProspectiveFamilies: {
            name: "ViewProspectiveFamilies",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective proforma invoice item info for the given family ID and proforma invoice ID
         *
         * @generated from rpc Scailo.ProformaInvoicesService.ViewProspectiveProformaInvoiceItem
         */
        viewProspectiveProformaInvoiceItem: {
            name: "ViewProspectiveProformaInvoiceItem",
            I: proforma_invoices_scailo_pb_js_1.ProformaInvoiceItemProspectiveInfoRequest,
            O: proforma_invoices_scailo_pb_js_1.ProformaInvoicesServiceItemCreateRequest,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download proforma invoice with the given IdentifierUUID (can be used to allow public downloads)
         *
         * @generated from rpc Scailo.ProformaInvoicesService.DownloadByUUID
         */
        downloadByUUID: {
            name: "DownloadByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the associated sales invoice ID that is denoted by the identifier in the response for the goods dispatch that is denoted by the identifier in the request
         *
         * @generated from rpc Scailo.ProformaInvoicesService.ViewAssociatedSalesInvoiceID
         */
        viewAssociatedSalesInvoiceID: {
            name: "ViewAssociatedSalesInvoiceID",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Checks if the Goods Dispatch has been billed
         *
         * @generated from rpc Scailo.ProformaInvoicesService.IsBilled
         */
        isBilled: {
            name: "IsBilled",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.BooleanResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View already added quantities
         *
         * @generated from rpc Scailo.ProformaInvoicesService.ViewAddedFamilyQuantityForSource
         */
        viewAddedFamilyQuantityForSource: {
            name: "ViewAddedFamilyQuantityForSource",
            I: proforma_invoices_scailo_pb_js_1.ProformaInvoicesServiceAlreadyAddedQuantityForSourceRequest,
            O: base_scailo_pb_js_1.DualQuantitiesResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.ProformaInvoicesService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: proforma_invoices_scailo_pb_js_1.ProformaInvoicesServiceSearchAllReq,
            O: proforma_invoices_scailo_pb_js_1.ProformaInvoicesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.ProformaInvoicesService.Filter
         */
        filter: {
            name: "Filter",
            I: proforma_invoices_scailo_pb_js_1.ProformaInvoicesServiceFilterReq,
            O: proforma_invoices_scailo_pb_js_1.ProformaInvoicesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.ProformaInvoicesService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.ProformaInvoicesService.Count
         */
        count: {
            name: "Count",
            I: proforma_invoices_scailo_pb_js_1.ProformaInvoicesServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Returns the sum of the total value of all the records that match the given criteria
         *
         * @generated from rpc Scailo.ProformaInvoicesService.AccruedValue
         */
        accruedValue: {
            name: "AccruedValue",
            I: proforma_invoices_scailo_pb_js_1.ProformaInvoicesServiceCountReq,
            O: base_scailo_pb_js_1.SumResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
