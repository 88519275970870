"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file equations_work_orders.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.EquationsWorkOrdersServiceSearchAllReq = exports.EquationsWorkOrdersServiceCountReq = exports.EquationsWorkOrdersServiceFilterReq = exports.EquationsWorkOrdersServicePaginationResponse = exports.EquationsWorkOrdersServicePaginationReq = exports.EquationsWorkOrdersServicePaginatedItemsResponse = exports.EquationWorkOrderItemsSearchRequest = exports.EquationWorkOrderItemHistoryRequest = exports.EquationsWorkOrdersItemsList = exports.EquationsWorkOrdersList = exports.EquationWorkOrderItem = exports.EquationsWorkOrdersServiceItemUpdateRequest = exports.EquationsWorkOrdersServiceItemCreateRequest = exports.EquationWorkOrder = exports.EquationsWorkOrdersServiceUpdateRequest = exports.EquationsWorkOrdersServiceCreateRequest = exports.EQUATION_WORK_ORDER_SORT_KEY = exports.EQUATION_WORK_ORDER_ITEM_STATUS = exports.EQUATION_WORK_ORDER_ITEM_SORT_KEY = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.EQUATION_WORK_ORDER_ITEM_SORT_KEY
 */
var EQUATION_WORK_ORDER_ITEM_SORT_KEY;
(function (EQUATION_WORK_ORDER_ITEM_SORT_KEY) {
    /**
     * Fetch results by id
     *
     * @generated from enum value: EQUATION_WORK_ORDER_ITEM_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    EQUATION_WORK_ORDER_ITEM_SORT_KEY[EQUATION_WORK_ORDER_ITEM_SORT_KEY["EQUATION_WORK_ORDER_ITEM_SORT_KEY_ID_UNSPECIFIED"] = 0] = "EQUATION_WORK_ORDER_ITEM_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch results by the creation timestamp
     *
     * @generated from enum value: EQUATION_WORK_ORDER_ITEM_SORT_KEY_CREATED_AT = 1;
     */
    EQUATION_WORK_ORDER_ITEM_SORT_KEY[EQUATION_WORK_ORDER_ITEM_SORT_KEY["EQUATION_WORK_ORDER_ITEM_SORT_KEY_CREATED_AT"] = 1] = "EQUATION_WORK_ORDER_ITEM_SORT_KEY_CREATED_AT";
    /**
     * Fetch results by the modified timestamp
     *
     * @generated from enum value: EQUATION_WORK_ORDER_ITEM_SORT_KEY_MODIFIED_AT = 2;
     */
    EQUATION_WORK_ORDER_ITEM_SORT_KEY[EQUATION_WORK_ORDER_ITEM_SORT_KEY["EQUATION_WORK_ORDER_ITEM_SORT_KEY_MODIFIED_AT"] = 2] = "EQUATION_WORK_ORDER_ITEM_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch results by the approved on timestamp
     *
     * @generated from enum value: EQUATION_WORK_ORDER_ITEM_SORT_KEY_APPROVED_ON = 3;
     */
    EQUATION_WORK_ORDER_ITEM_SORT_KEY[EQUATION_WORK_ORDER_ITEM_SORT_KEY["EQUATION_WORK_ORDER_ITEM_SORT_KEY_APPROVED_ON"] = 3] = "EQUATION_WORK_ORDER_ITEM_SORT_KEY_APPROVED_ON";
    /**
     * Fetch results by the approved by field
     *
     * @generated from enum value: EQUATION_WORK_ORDER_ITEM_SORT_KEY_APPROVED_BY = 4;
     */
    EQUATION_WORK_ORDER_ITEM_SORT_KEY[EQUATION_WORK_ORDER_ITEM_SORT_KEY["EQUATION_WORK_ORDER_ITEM_SORT_KEY_APPROVED_BY"] = 4] = "EQUATION_WORK_ORDER_ITEM_SORT_KEY_APPROVED_BY";
    /**
     * Fetch results by the approver's role ID
     *
     * @generated from enum value: EQUATION_WORK_ORDER_ITEM_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    EQUATION_WORK_ORDER_ITEM_SORT_KEY[EQUATION_WORK_ORDER_ITEM_SORT_KEY["EQUATION_WORK_ORDER_ITEM_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "EQUATION_WORK_ORDER_ITEM_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch results by the family ID
     *
     * @generated from enum value: EQUATION_WORK_ORDER_ITEM_SORT_KEY_FAMILY_ID = 10;
     */
    EQUATION_WORK_ORDER_ITEM_SORT_KEY[EQUATION_WORK_ORDER_ITEM_SORT_KEY["EQUATION_WORK_ORDER_ITEM_SORT_KEY_FAMILY_ID"] = 10] = "EQUATION_WORK_ORDER_ITEM_SORT_KEY_FAMILY_ID";
    /**
     * Fetch results by the quantity
     *
     * @generated from enum value: EQUATION_WORK_ORDER_ITEM_SORT_KEY_QUANTITY = 11;
     */
    EQUATION_WORK_ORDER_ITEM_SORT_KEY[EQUATION_WORK_ORDER_ITEM_SORT_KEY["EQUATION_WORK_ORDER_ITEM_SORT_KEY_QUANTITY"] = 11] = "EQUATION_WORK_ORDER_ITEM_SORT_KEY_QUANTITY";
    /**
     * Fetch ordered results by the unit price
     *
     * @generated from enum value: EQUATION_WORK_ORDER_ITEM_SORT_KEY_UNIT_PRICE = 12;
     */
    EQUATION_WORK_ORDER_ITEM_SORT_KEY[EQUATION_WORK_ORDER_ITEM_SORT_KEY["EQUATION_WORK_ORDER_ITEM_SORT_KEY_UNIT_PRICE"] = 12] = "EQUATION_WORK_ORDER_ITEM_SORT_KEY_UNIT_PRICE";
})(EQUATION_WORK_ORDER_ITEM_SORT_KEY || (exports.EQUATION_WORK_ORDER_ITEM_SORT_KEY = EQUATION_WORK_ORDER_ITEM_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(EQUATION_WORK_ORDER_ITEM_SORT_KEY)
protobuf_1.proto3.util.setEnumType(EQUATION_WORK_ORDER_ITEM_SORT_KEY, "Scailo.EQUATION_WORK_ORDER_ITEM_SORT_KEY", [
    { no: 0, name: "EQUATION_WORK_ORDER_ITEM_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "EQUATION_WORK_ORDER_ITEM_SORT_KEY_CREATED_AT" },
    { no: 2, name: "EQUATION_WORK_ORDER_ITEM_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "EQUATION_WORK_ORDER_ITEM_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "EQUATION_WORK_ORDER_ITEM_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "EQUATION_WORK_ORDER_ITEM_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 10, name: "EQUATION_WORK_ORDER_ITEM_SORT_KEY_FAMILY_ID" },
    { no: 11, name: "EQUATION_WORK_ORDER_ITEM_SORT_KEY_QUANTITY" },
    { no: 12, name: "EQUATION_WORK_ORDER_ITEM_SORT_KEY_UNIT_PRICE" },
]);
/**
 *
 * Describes the applicable statuses of equation work order items
 *
 * @generated from enum Scailo.EQUATION_WORK_ORDER_ITEM_STATUS
 */
var EQUATION_WORK_ORDER_ITEM_STATUS;
(function (EQUATION_WORK_ORDER_ITEM_STATUS) {
    /**
     * Denotes that status be disregarded. This is used only within search APIs
     *
     * @generated from enum value: EQUATION_WORK_ORDER_ITEM_STATUS_ANY_UNSPECIFIED = 0;
     */
    EQUATION_WORK_ORDER_ITEM_STATUS[EQUATION_WORK_ORDER_ITEM_STATUS["EQUATION_WORK_ORDER_ITEM_STATUS_ANY_UNSPECIFIED"] = 0] = "EQUATION_WORK_ORDER_ITEM_STATUS_ANY_UNSPECIFIED";
    /**
     * Denotes that the equation work order items must have been approved
     *
     * @generated from enum value: EQUATION_WORK_ORDER_ITEM_STATUS_APPROVED = 1;
     */
    EQUATION_WORK_ORDER_ITEM_STATUS[EQUATION_WORK_ORDER_ITEM_STATUS["EQUATION_WORK_ORDER_ITEM_STATUS_APPROVED"] = 1] = "EQUATION_WORK_ORDER_ITEM_STATUS_APPROVED";
    /**
     * Denotes that the equation work order items must be waiting for approval
     *
     * @generated from enum value: EQUATION_WORK_ORDER_ITEM_STATUS_UNAPPROVED = 2;
     */
    EQUATION_WORK_ORDER_ITEM_STATUS[EQUATION_WORK_ORDER_ITEM_STATUS["EQUATION_WORK_ORDER_ITEM_STATUS_UNAPPROVED"] = 2] = "EQUATION_WORK_ORDER_ITEM_STATUS_UNAPPROVED";
})(EQUATION_WORK_ORDER_ITEM_STATUS || (exports.EQUATION_WORK_ORDER_ITEM_STATUS = EQUATION_WORK_ORDER_ITEM_STATUS = {}));
// Retrieve enum metadata with: proto3.getEnumType(EQUATION_WORK_ORDER_ITEM_STATUS)
protobuf_1.proto3.util.setEnumType(EQUATION_WORK_ORDER_ITEM_STATUS, "Scailo.EQUATION_WORK_ORDER_ITEM_STATUS", [
    { no: 0, name: "EQUATION_WORK_ORDER_ITEM_STATUS_ANY_UNSPECIFIED" },
    { no: 1, name: "EQUATION_WORK_ORDER_ITEM_STATUS_APPROVED" },
    { no: 2, name: "EQUATION_WORK_ORDER_ITEM_STATUS_UNAPPROVED" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.EQUATION_WORK_ORDER_SORT_KEY
 */
var EQUATION_WORK_ORDER_SORT_KEY;
(function (EQUATION_WORK_ORDER_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: EQUATION_WORK_ORDER_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    EQUATION_WORK_ORDER_SORT_KEY[EQUATION_WORK_ORDER_SORT_KEY["EQUATION_WORK_ORDER_SORT_KEY_ID_UNSPECIFIED"] = 0] = "EQUATION_WORK_ORDER_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: EQUATION_WORK_ORDER_SORT_KEY_CREATED_AT = 1;
     */
    EQUATION_WORK_ORDER_SORT_KEY[EQUATION_WORK_ORDER_SORT_KEY["EQUATION_WORK_ORDER_SORT_KEY_CREATED_AT"] = 1] = "EQUATION_WORK_ORDER_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: EQUATION_WORK_ORDER_SORT_KEY_MODIFIED_AT = 2;
     */
    EQUATION_WORK_ORDER_SORT_KEY[EQUATION_WORK_ORDER_SORT_KEY["EQUATION_WORK_ORDER_SORT_KEY_MODIFIED_AT"] = 2] = "EQUATION_WORK_ORDER_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: EQUATION_WORK_ORDER_SORT_KEY_APPROVED_ON = 3;
     */
    EQUATION_WORK_ORDER_SORT_KEY[EQUATION_WORK_ORDER_SORT_KEY["EQUATION_WORK_ORDER_SORT_KEY_APPROVED_ON"] = 3] = "EQUATION_WORK_ORDER_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: EQUATION_WORK_ORDER_SORT_KEY_APPROVED_BY = 4;
     */
    EQUATION_WORK_ORDER_SORT_KEY[EQUATION_WORK_ORDER_SORT_KEY["EQUATION_WORK_ORDER_SORT_KEY_APPROVED_BY"] = 4] = "EQUATION_WORK_ORDER_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: EQUATION_WORK_ORDER_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    EQUATION_WORK_ORDER_SORT_KEY[EQUATION_WORK_ORDER_SORT_KEY["EQUATION_WORK_ORDER_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "EQUATION_WORK_ORDER_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: EQUATION_WORK_ORDER_SORT_KEY_COMPLETED_ON = 6;
     */
    EQUATION_WORK_ORDER_SORT_KEY[EQUATION_WORK_ORDER_SORT_KEY["EQUATION_WORK_ORDER_SORT_KEY_COMPLETED_ON"] = 6] = "EQUATION_WORK_ORDER_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the name
     *
     * @generated from enum value: EQUATION_WORK_ORDER_SORT_KEY_NAME = 10;
     */
    EQUATION_WORK_ORDER_SORT_KEY[EQUATION_WORK_ORDER_SORT_KEY["EQUATION_WORK_ORDER_SORT_KEY_NAME"] = 10] = "EQUATION_WORK_ORDER_SORT_KEY_NAME";
    /**
     * Fetch ordered results by the work order ID
     *
     * @generated from enum value: EQUATION_WORK_ORDER_SORT_KEY_WORK_ORDER_ID = 11;
     */
    EQUATION_WORK_ORDER_SORT_KEY[EQUATION_WORK_ORDER_SORT_KEY["EQUATION_WORK_ORDER_SORT_KEY_WORK_ORDER_ID"] = 11] = "EQUATION_WORK_ORDER_SORT_KEY_WORK_ORDER_ID";
    /**
     * Fetch ordered results by the amendment count
     *
     * @generated from enum value: EQUATION_WORK_ORDER_SORT_KEY_AMENDMENT_COUNT = 15;
     */
    EQUATION_WORK_ORDER_SORT_KEY[EQUATION_WORK_ORDER_SORT_KEY["EQUATION_WORK_ORDER_SORT_KEY_AMENDMENT_COUNT"] = 15] = "EQUATION_WORK_ORDER_SORT_KEY_AMENDMENT_COUNT";
})(EQUATION_WORK_ORDER_SORT_KEY || (exports.EQUATION_WORK_ORDER_SORT_KEY = EQUATION_WORK_ORDER_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(EQUATION_WORK_ORDER_SORT_KEY)
protobuf_1.proto3.util.setEnumType(EQUATION_WORK_ORDER_SORT_KEY, "Scailo.EQUATION_WORK_ORDER_SORT_KEY", [
    { no: 0, name: "EQUATION_WORK_ORDER_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "EQUATION_WORK_ORDER_SORT_KEY_CREATED_AT" },
    { no: 2, name: "EQUATION_WORK_ORDER_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "EQUATION_WORK_ORDER_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "EQUATION_WORK_ORDER_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "EQUATION_WORK_ORDER_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 6, name: "EQUATION_WORK_ORDER_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "EQUATION_WORK_ORDER_SORT_KEY_NAME" },
    { no: 11, name: "EQUATION_WORK_ORDER_SORT_KEY_WORK_ORDER_ID" },
    { no: 15, name: "EQUATION_WORK_ORDER_SORT_KEY_AMENDMENT_COUNT" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.EquationsWorkOrdersServiceCreateRequest
 */
class EquationsWorkOrdersServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The name of the equation work order
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The ID of the work order that this equation belongs to
     *
     * @generated from field: uint64 work_order_id = 12;
     */
    workOrderId = protobuf_1.protoInt64.zero;
    /**
     * The description of the equation work order
     *
     * @generated from field: string description = 13;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.EquationsWorkOrdersServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "work_order_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new EquationsWorkOrdersServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new EquationsWorkOrdersServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new EquationsWorkOrdersServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(EquationsWorkOrdersServiceCreateRequest, a, b);
    }
}
exports.EquationsWorkOrdersServiceCreateRequest = EquationsWorkOrdersServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.EquationsWorkOrdersServiceUpdateRequest
 */
class EquationsWorkOrdersServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The name of the equation work order
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The description of the equation work order
     *
     * @generated from field: string description = 13;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.EquationsWorkOrdersServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new EquationsWorkOrdersServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new EquationsWorkOrdersServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new EquationsWorkOrdersServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(EquationsWorkOrdersServiceUpdateRequest, a, b);
    }
}
exports.EquationsWorkOrdersServiceUpdateRequest = EquationsWorkOrdersServiceUpdateRequest;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.EquationWorkOrder
 */
class EquationWorkOrder extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this equation work order
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this equation work order
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this equation work order
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this equation work order was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The name of the equation work order
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The ID of the work order that this equation belongs to
     *
     * @generated from field: uint64 work_order_id = 12;
     */
    workOrderId = protobuf_1.protoInt64.zero;
    /**
     * The description of the equation work order
     *
     * @generated from field: string description = 13;
     */
    description = "";
    /**
     * The list of associated equation work order items
     *
     * @generated from field: repeated Scailo.EquationWorkOrderItem list = 20;
     */
    list = [];
    /**
     * The total price of the equation work order
     *
     * @generated from field: double total_price = 40;
     */
    totalPrice = 0;
    /**
     * The number of times that the equation work order has been amended
     *
     * @generated from field: uint64 amendment_count = 70;
     */
    amendmentCount = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.EquationWorkOrder";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "work_order_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "list", kind: "message", T: EquationWorkOrderItem, repeated: true },
        { no: 40, name: "total_price", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
        { no: 70, name: "amendment_count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new EquationWorkOrder().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new EquationWorkOrder().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new EquationWorkOrder().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(EquationWorkOrder, a, b);
    }
}
exports.EquationWorkOrder = EquationWorkOrder;
/**
 *
 * Describes the parameters required to add an item to a equation work order
 *
 * @generated from message Scailo.EquationsWorkOrdersServiceItemCreateRequest
 */
class EquationsWorkOrdersServiceItemCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the ID of the equation work order
     *
     * @generated from field: uint64 equation_id = 10;
     */
    equationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family that the equation depends upon
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The quantity of the associated family to be used as the multiplier
     *
     * @generated from field: uint64 quantity = 12;
     */
    quantity = protobuf_1.protoInt64.zero;
    /**
     * The optional unit price of this family
     *
     * @generated from field: uint64 unit_price = 13;
     */
    unitPrice = protobuf_1.protoInt64.zero;
    /**
     * The optional specifications
     *
     * @generated from field: string specifications = 14;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.EquationsWorkOrdersServiceItemCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "equation_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "unit_price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new EquationsWorkOrdersServiceItemCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new EquationsWorkOrdersServiceItemCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new EquationsWorkOrdersServiceItemCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(EquationsWorkOrdersServiceItemCreateRequest, a, b);
    }
}
exports.EquationsWorkOrdersServiceItemCreateRequest = EquationsWorkOrdersServiceItemCreateRequest;
/**
 *
 * Describes the parameters required to update an item in a equation work order
 *
 * @generated from message Scailo.EquationsWorkOrdersServiceItemUpdateRequest
 */
class EquationsWorkOrdersServiceItemUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * The quantity of the associated family to be used as the multiplier
     *
     * @generated from field: uint64 quantity = 12;
     */
    quantity = protobuf_1.protoInt64.zero;
    /**
     * The optional unit price of this family
     *
     * @generated from field: uint64 unit_price = 13;
     */
    unitPrice = protobuf_1.protoInt64.zero;
    /**
     * The optional specifications
     *
     * @generated from field: string specifications = 14;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.EquationsWorkOrdersServiceItemUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "unit_price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new EquationsWorkOrdersServiceItemUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new EquationsWorkOrdersServiceItemUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new EquationsWorkOrdersServiceItemUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(EquationsWorkOrdersServiceItemUpdateRequest, a, b);
    }
}
exports.EquationsWorkOrdersServiceItemUpdateRequest = EquationsWorkOrdersServiceItemUpdateRequest;
/**
 *
 * Describes the parameters that constitute an item associated to a equation work order
 *
 * @generated from message Scailo.EquationWorkOrderItem
 */
class EquationWorkOrderItem extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this equation work order
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the ID of the equation work order
     *
     * @generated from field: uint64 equation_id = 10;
     */
    equationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family that the equation depends upon
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The quantity of the associated family to be used as the multiplier
     *
     * @generated from field: uint64 quantity = 12;
     */
    quantity = protobuf_1.protoInt64.zero;
    /**
     * The optional unit price of this family
     *
     * @generated from field: uint64 unit_price = 13;
     */
    unitPrice = protobuf_1.protoInt64.zero;
    /**
     * The optional specifications
     *
     * @generated from field: string specifications = 14;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.EquationWorkOrderItem";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "equation_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "unit_price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new EquationWorkOrderItem().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new EquationWorkOrderItem().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new EquationWorkOrderItem().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(EquationWorkOrderItem, a, b);
    }
}
exports.EquationWorkOrderItem = EquationWorkOrderItem;
/**
 *
 * Describes the message consisting of the list of equations work orders
 *
 * @generated from message Scailo.EquationsWorkOrdersList
 */
class EquationsWorkOrdersList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.EquationWorkOrder list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.EquationsWorkOrdersList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: EquationWorkOrder, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new EquationsWorkOrdersList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new EquationsWorkOrdersList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new EquationsWorkOrdersList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(EquationsWorkOrdersList, a, b);
    }
}
exports.EquationsWorkOrdersList = EquationsWorkOrdersList;
/**
 *
 * Describes the message consisting of the list of equation work order items
 *
 * @generated from message Scailo.EquationsWorkOrdersItemsList
 */
class EquationsWorkOrdersItemsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.EquationWorkOrderItem list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.EquationsWorkOrdersItemsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: EquationWorkOrderItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new EquationsWorkOrdersItemsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new EquationsWorkOrdersItemsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new EquationsWorkOrdersItemsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(EquationsWorkOrdersItemsList, a, b);
    }
}
exports.EquationsWorkOrdersItemsList = EquationsWorkOrdersItemsList;
/**
 *
 * Describes the parameters that are required to retrieve the history of the record
 *
 * @generated from message Scailo.EquationWorkOrderItemHistoryRequest
 */
class EquationWorkOrderItemHistoryRequest extends protobuf_1.Message {
    /**
     * Stores the ID of the equation work order
     *
     * @generated from field: uint64 equation_id = 10;
     */
    equationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family that the equation depends upon
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.EquationWorkOrderItemHistoryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "equation_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new EquationWorkOrderItemHistoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new EquationWorkOrderItemHistoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new EquationWorkOrderItemHistoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(EquationWorkOrderItemHistoryRequest, a, b);
    }
}
exports.EquationWorkOrderItemHistoryRequest = EquationWorkOrderItemHistoryRequest;
/**
 *
 * Describes the request payload to retrieve approved or unapproved items.
 *
 * @generated from message Scailo.EquationWorkOrderItemsSearchRequest
 */
class EquationWorkOrderItemsSearchRequest extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.EQUATION_WORK_ORDER_ITEM_SORT_KEY sort_key = 5;
     */
    sortKey = EQUATION_WORK_ORDER_ITEM_SORT_KEY.EQUATION_WORK_ORDER_ITEM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * The status of the items
     *
     * @generated from field: Scailo.EQUATION_WORK_ORDER_ITEM_STATUS status = 7;
     */
    status = EQUATION_WORK_ORDER_ITEM_STATUS.EQUATION_WORK_ORDER_ITEM_STATUS_ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 10;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 11;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 12;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 13;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the equation work order
     *
     * @generated from field: uint64 equation_id = 20;
     */
    equationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 21;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 40;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.EquationWorkOrderItemsSearchRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(EQUATION_WORK_ORDER_ITEM_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 7, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(EQUATION_WORK_ORDER_ITEM_STATUS) },
        { no: 10, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "equation_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new EquationWorkOrderItemsSearchRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new EquationWorkOrderItemsSearchRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new EquationWorkOrderItemsSearchRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(EquationWorkOrderItemsSearchRequest, a, b);
    }
}
exports.EquationWorkOrderItemsSearchRequest = EquationWorkOrderItemsSearchRequest;
/**
 *
 * Describes the response to a pagination items request
 *
 * @generated from message Scailo.EquationsWorkOrdersServicePaginatedItemsResponse
 */
class EquationsWorkOrdersServicePaginatedItemsResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.EquationWorkOrderItem payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.EquationsWorkOrdersServicePaginatedItemsResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: EquationWorkOrderItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new EquationsWorkOrdersServicePaginatedItemsResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new EquationsWorkOrdersServicePaginatedItemsResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new EquationsWorkOrdersServicePaginatedItemsResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(EquationsWorkOrdersServicePaginatedItemsResponse, a, b);
    }
}
exports.EquationsWorkOrdersServicePaginatedItemsResponse = EquationsWorkOrdersServicePaginatedItemsResponse;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.EquationsWorkOrdersServicePaginationReq
 */
class EquationsWorkOrdersServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.EQUATION_WORK_ORDER_SORT_KEY sort_key = 5;
     */
    sortKey = EQUATION_WORK_ORDER_SORT_KEY.EQUATION_WORK_ORDER_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this equation work order
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.EquationsWorkOrdersServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(EQUATION_WORK_ORDER_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new EquationsWorkOrdersServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new EquationsWorkOrdersServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new EquationsWorkOrdersServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(EquationsWorkOrdersServicePaginationReq, a, b);
    }
}
exports.EquationsWorkOrdersServicePaginationReq = EquationsWorkOrdersServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.EquationsWorkOrdersServicePaginationResponse
 */
class EquationsWorkOrdersServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.EquationWorkOrder payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.EquationsWorkOrdersServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: EquationWorkOrder, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new EquationsWorkOrdersServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new EquationsWorkOrdersServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new EquationsWorkOrdersServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(EquationsWorkOrdersServicePaginationResponse, a, b);
    }
}
exports.EquationsWorkOrdersServicePaginationResponse = EquationsWorkOrdersServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.EquationsWorkOrdersServiceFilterReq
 */
class EquationsWorkOrdersServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.EQUATION_WORK_ORDER_SORT_KEY sort_key = 5;
     */
    sortKey = EQUATION_WORK_ORDER_SORT_KEY.EQUATION_WORK_ORDER_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this equation work order
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The name of the equation work order
     *
     * @generated from field: string name = 20;
     */
    name = "";
    /**
     * The ID of the work order that this equation belongs to
     *
     * @generated from field: uint64 work_order_id = 21;
     */
    workOrderId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the constituent family that is part of an equation
     *
     * @generated from field: uint64 constituent_family_id = 30;
     */
    constituentFamilyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.EquationsWorkOrdersServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(EQUATION_WORK_ORDER_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "work_order_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "constituent_family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new EquationsWorkOrdersServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new EquationsWorkOrdersServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new EquationsWorkOrdersServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(EquationsWorkOrdersServiceFilterReq, a, b);
    }
}
exports.EquationsWorkOrdersServiceFilterReq = EquationsWorkOrdersServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.EquationsWorkOrdersServiceCountReq
 */
class EquationsWorkOrdersServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this equation work order
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The name of the equation work order
     *
     * @generated from field: string name = 20;
     */
    name = "";
    /**
     * The ID of the work order that this equation belongs to
     *
     * @generated from field: uint64 work_order_id = 21;
     */
    workOrderId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the constituent family that is part of an equation
     *
     * @generated from field: uint64 constituent_family_id = 30;
     */
    constituentFamilyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.EquationsWorkOrdersServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "work_order_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "constituent_family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new EquationsWorkOrdersServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new EquationsWorkOrdersServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new EquationsWorkOrdersServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(EquationsWorkOrdersServiceCountReq, a, b);
    }
}
exports.EquationsWorkOrdersServiceCountReq = EquationsWorkOrdersServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.EquationsWorkOrdersServiceSearchAllReq
 */
class EquationsWorkOrdersServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.EQUATION_WORK_ORDER_SORT_KEY sort_key = 5;
     */
    sortKey = EQUATION_WORK_ORDER_SORT_KEY.EQUATION_WORK_ORDER_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.EquationsWorkOrdersServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(EQUATION_WORK_ORDER_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new EquationsWorkOrdersServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new EquationsWorkOrdersServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new EquationsWorkOrdersServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(EquationsWorkOrdersServiceSearchAllReq, a, b);
    }
}
exports.EquationsWorkOrdersServiceSearchAllReq = EquationsWorkOrdersServiceSearchAllReq;
