import { getTransport } from "./clients";
import { roleSelf } from "./fetches";
import { setupLogoutHandler, setupNavbarVariables } from "./login";
import * as routes from "./routes";
import { setUserRole, showFailureAlert } from "./utilities";
import { init } from "./websocket";

window.addEventListener("load", async (evt) => {
    evt.preventDefault();
    if (location.pathname != "/") {
        // Perhaps, retrieve the user's role here and store it in localStorage
        const transport = getTransport();
        let [userRole] = await Promise.all([
            roleSelf(transport),
        ]);
        setUserRole(userRole);
        setupLogoutHandler();
        setupNavbarVariables();
        init();
        setupExpiryHandler();
    }
    routes.start();
});

const showAlertAt = 60 * 1000 * 5;
function setupExpiryHandler() {
    let expiresAt = localStorage.getItem("expires_at");
    if (expiresAt != null) {
        let expiresAtDate = new Date(parseInt(expiresAt) * 1000);
        let now = new Date();
        let diff = expiresAtDate.getTime() - now.getTime();
        if (diff < 0) {
            try {
                document.getElementById("logout")?.click();
            } catch (e) {}
        }
        if (diff < showAlertAt) {
            try {
                showFailureAlert(`Your session is going to expire in ${(diff / 60 / 1000).toFixed(0)} minute(s). Kindly save your work and login again.`, { timeoutInMs: 20000 });
            } catch (e) {}
        }
    }
    setTimeout(() => {
        setupExpiryHandler();
    }, 60 * 1000);
}