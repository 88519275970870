"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file sales_invoices.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.SalesInvoicesService = void 0;
const sales_invoices_scailo_pb_js_1 = require("./sales_invoices.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
const families_scailo_pb_js_1 = require("./families.scailo_pb.js");
/**
 *
 * Describes the common methods applicable on each sales invoice
 *
 * @generated from service Scailo.SalesInvoicesService
 */
exports.SalesInvoicesService = {
    typeName: "Scailo.SalesInvoicesService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.SalesInvoicesService.Create
         */
        create: {
            name: "Create",
            I: sales_invoices_scailo_pb_js_1.SalesInvoicesServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.SalesInvoicesService.Draft
         */
        draft: {
            name: "Draft",
            I: sales_invoices_scailo_pb_js_1.SalesInvoicesServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.SalesInvoicesService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: sales_invoices_scailo_pb_js_1.SalesInvoicesServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.SalesInvoicesService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.SalesInvoicesService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.SalesInvoicesService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.SalesInvoicesService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.SalesInvoicesService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: sales_invoices_scailo_pb_js_1.SalesInvoicesServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.SalesInvoicesService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.SalesInvoicesService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.SalesInvoicesService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.SalesInvoicesService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.SalesInvoicesService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.SalesInvoicesService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * @generated from rpc Scailo.SalesInvoicesService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send Email
         *
         * @generated from rpc Scailo.SalesInvoicesService.SendEmail
         */
        sendEmail: {
            name: "SendEmail",
            I: base_scailo_pb_js_1.IdentifierWithEmailAttributes,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Autofill the sales invoice
         *
         * @generated from rpc Scailo.SalesInvoicesService.Autofill
         */
        autofill: {
            name: "Autofill",
            I: sales_invoices_scailo_pb_js_1.SalesInvoicesServiceAutofillRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Amend the sales invoice and send for revision
         *
         * @generated from rpc Scailo.SalesInvoicesService.Amend
         */
        amend: {
            name: "Amend",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an item to a sales invoice
         *
         * @generated from rpc Scailo.SalesInvoicesService.AddSalesInvoiceItem
         */
        addSalesInvoiceItem: {
            name: "AddSalesInvoiceItem",
            I: sales_invoices_scailo_pb_js_1.SalesInvoicesServiceItemCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify an item in a sales invoice
         *
         * @generated from rpc Scailo.SalesInvoicesService.ModifySalesInvoiceItem
         */
        modifySalesInvoiceItem: {
            name: "ModifySalesInvoiceItem",
            I: sales_invoices_scailo_pb_js_1.SalesInvoicesServiceItemUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve an item in a sales invoice
         *
         * @generated from rpc Scailo.SalesInvoicesService.ApproveSalesInvoiceItem
         */
        approveSalesInvoiceItem: {
            name: "ApproveSalesInvoiceItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an item in a sales invoice
         *
         * @generated from rpc Scailo.SalesInvoicesService.DeleteSalesInvoiceItem
         */
        deleteSalesInvoiceItem: {
            name: "DeleteSalesInvoiceItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder items in a sales invoice
         *
         * @generated from rpc Scailo.SalesInvoicesService.ReorderSalesInvoiceItems
         */
        reorderSalesInvoiceItems: {
            name: "ReorderSalesInvoiceItems",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Sales Invoice Item by ID
         *
         * @generated from rpc Scailo.SalesInvoicesService.ViewSalesInvoiceItemByID
         */
        viewSalesInvoiceItemByID: {
            name: "ViewSalesInvoiceItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: sales_invoices_scailo_pb_js_1.SalesInvoiceItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved sales invoice items for given sales invoice ID
         *
         * @generated from rpc Scailo.SalesInvoicesService.ViewApprovedSalesInvoiceItems
         */
        viewApprovedSalesInvoiceItems: {
            name: "ViewApprovedSalesInvoiceItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: sales_invoices_scailo_pb_js_1.SalesInvoiceItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved sales invoice items for given sales invoice ID
         *
         * @generated from rpc Scailo.SalesInvoicesService.ViewUnapprovedSalesInvoiceItems
         */
        viewUnapprovedSalesInvoiceItems: {
            name: "ViewUnapprovedSalesInvoiceItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: sales_invoices_scailo_pb_js_1.SalesInvoiceItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the sales invoice item
         *
         * @generated from rpc Scailo.SalesInvoicesService.ViewSalesInvoiceItemHistory
         */
        viewSalesInvoiceItemHistory: {
            name: "ViewSalesInvoiceItemHistory",
            I: sales_invoices_scailo_pb_js_1.SalesInvoiceItemHistoryRequest,
            O: sales_invoices_scailo_pb_js_1.SalesInvoiceItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved sales invoice items for given sales invoice ID with pagination
         *
         * @generated from rpc Scailo.SalesInvoicesService.ViewPaginatedApprovedSalesInvoiceItems
         */
        viewPaginatedApprovedSalesInvoiceItems: {
            name: "ViewPaginatedApprovedSalesInvoiceItems",
            I: sales_invoices_scailo_pb_js_1.SalesInvoiceItemsSearchRequest,
            O: sales_invoices_scailo_pb_js_1.SalesInvoicesServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved sales invoice items for given sales invoice ID with pagination
         *
         * @generated from rpc Scailo.SalesInvoicesService.ViewPaginatedUnapprovedSalesInvoiceItems
         */
        viewPaginatedUnapprovedSalesInvoiceItems: {
            name: "ViewPaginatedUnapprovedSalesInvoiceItems",
            I: sales_invoices_scailo_pb_js_1.SalesInvoiceItemsSearchRequest,
            O: sales_invoices_scailo_pb_js_1.SalesInvoicesServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through sales invoice items with pagination
         *
         * @generated from rpc Scailo.SalesInvoicesService.SearchItemsWithPagination
         */
        searchItemsWithPagination: {
            name: "SearchItemsWithPagination",
            I: sales_invoices_scailo_pb_js_1.SalesInvoiceItemsSearchRequest,
            O: sales_invoices_scailo_pb_js_1.SalesInvoicesServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * CSV operations
         * Download the CSV file that could be used to upload items from the filled CSV file. The same file can also be used as a template when there are no existing records
         *
         * @generated from rpc Scailo.SalesInvoicesService.DownloadItemsAsCSV
         */
        downloadItemsAsCSV: {
            name: "DownloadItemsAsCSV",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Upload items using a CSV file. This is an idempotent operation. All the existing items are deleted before adding the items from the file.
         *
         * @generated from rpc Scailo.SalesInvoicesService.UploadSalesInvoiceItems
         */
        uploadSalesInvoiceItems: {
            name: "UploadSalesInvoiceItems",
            I: base_scailo_pb_js_1.IdentifierUUIDWithFile,
            O: base_scailo_pb_js_1.IdentifiersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add a reference
         *
         * @generated from rpc Scailo.SalesInvoicesService.AddSalesInvoiceReference
         */
        addSalesInvoiceReference: {
            name: "AddSalesInvoiceReference",
            I: sales_invoices_scailo_pb_js_1.SalesInvoicesServiceReferenceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve a reference
         *
         * @generated from rpc Scailo.SalesInvoicesService.ApproveSalesInvoiceReference
         */
        approveSalesInvoiceReference: {
            name: "ApproveSalesInvoiceReference",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete a reference
         *
         * @generated from rpc Scailo.SalesInvoicesService.DeleteSalesInvoiceReference
         */
        deleteSalesInvoiceReference: {
            name: "DeleteSalesInvoiceReference",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View a reference for the given ID
         *
         * @generated from rpc Scailo.SalesInvoicesService.ViewSalesInvoiceReferenceByID
         */
        viewSalesInvoiceReferenceByID: {
            name: "ViewSalesInvoiceReferenceByID",
            I: base_scailo_pb_js_1.Identifier,
            O: sales_invoices_scailo_pb_js_1.SalesInvoiceReference,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all references for given sales invoice ID
         *
         * @generated from rpc Scailo.SalesInvoicesService.ViewSalesInvoiceReferences
         */
        viewSalesInvoiceReferences: {
            name: "ViewSalesInvoiceReferences",
            I: base_scailo_pb_js_1.Identifier,
            O: sales_invoices_scailo_pb_js_1.SalesInvoiceReferencesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.SalesInvoicesService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: sales_invoices_scailo_pb_js_1.SalesInvoice,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID
         *
         * @generated from rpc Scailo.SalesInvoicesService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: sales_invoices_scailo_pb_js_1.SalesInvoice,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the ancillary parameters (UUIDs of the internal references) by UUID
         *
         * @generated from rpc Scailo.SalesInvoicesService.ViewAncillaryParametersByUUID
         */
        viewAncillaryParametersByUUID: {
            name: "ViewAncillaryParametersByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: sales_invoices_scailo_pb_js_1.SalesInvoiceAncillaryParameters,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.SalesInvoicesService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: sales_invoices_scailo_pb_js_1.SalesInvoicesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.SalesInvoicesService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: sales_invoices_scailo_pb_js_1.SalesInvoicesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.SalesInvoicesService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: sales_invoices_scailo_pb_js_1.SalesInvoicesServicePaginationReq,
            O: sales_invoices_scailo_pb_js_1.SalesInvoicesServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all the amendments made
         *
         * @generated from rpc Scailo.SalesInvoicesService.ViewAmendments
         */
        viewAmendments: {
            name: "ViewAmendments",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.AmendmentLogsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective families for the given sales invoice
         *
         * @generated from rpc Scailo.SalesInvoicesService.ViewProspectiveFamilies
         */
        viewProspectiveFamilies: {
            name: "ViewProspectiveFamilies",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective sales invoice item info for the given family ID and sales invoice ID
         *
         * @generated from rpc Scailo.SalesInvoicesService.ViewProspectiveSalesInvoiceItem
         */
        viewProspectiveSalesInvoiceItem: {
            name: "ViewProspectiveSalesInvoiceItem",
            I: sales_invoices_scailo_pb_js_1.SalesInvoiceItemProspectiveInfoRequest,
            O: sales_invoices_scailo_pb_js_1.SalesInvoicesServiceItemCreateRequest,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View dispatched (goods dispatch) statistics of the sales invoice
         *
         * @generated from rpc Scailo.SalesInvoicesService.ViewDispatchedStatistics
         */
        viewDispatchedStatistics: {
            name: "ViewDispatchedStatistics",
            I: base_scailo_pb_js_1.Identifier,
            O: sales_invoices_scailo_pb_js_1.SalesInvoiceDispatchedStatisticsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View already added quantities
         *
         * @generated from rpc Scailo.SalesInvoicesService.ViewAddedFamilyQuantityForSource
         */
        viewAddedFamilyQuantityForSource: {
            name: "ViewAddedFamilyQuantityForSource",
            I: sales_invoices_scailo_pb_js_1.SalesInvoicesServiceAlreadyAddedQuantityForSourceRequest,
            O: base_scailo_pb_js_1.DualQuantitiesResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download sales invoice with the given IdentifierUUID (can be used to allow public downloads)
         *
         * @generated from rpc Scailo.SalesInvoicesService.DownloadByUUID
         */
        downloadByUUID: {
            name: "DownloadByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.SalesInvoicesService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: sales_invoices_scailo_pb_js_1.SalesInvoicesServiceSearchAllReq,
            O: sales_invoices_scailo_pb_js_1.SalesInvoicesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.SalesInvoicesService.Filter
         */
        filter: {
            name: "Filter",
            I: sales_invoices_scailo_pb_js_1.SalesInvoicesServiceFilterReq,
            O: sales_invoices_scailo_pb_js_1.SalesInvoicesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.SalesInvoicesService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.SalesInvoicesService.Count
         */
        count: {
            name: "Count",
            I: sales_invoices_scailo_pb_js_1.SalesInvoicesServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Returns the sum of the total value of all the records that match the given criteria
         *
         * @generated from rpc Scailo.SalesInvoicesService.AccruedValue
         */
        accruedValue: {
            name: "AccruedValue",
            I: sales_invoices_scailo_pb_js_1.SalesInvoicesServiceCountReq,
            O: base_scailo_pb_js_1.SumResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
