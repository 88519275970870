"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file forms_fields.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormsFieldsServiceSearchAllReq = exports.FormsFieldsServiceCountReq = exports.FormsFieldsServiceFilterReq = exports.FormsFieldsServicePaginationReq = exports.FormsFieldsServiceUpdateRequest = exports.FormsFieldsServiceCreateRequest = exports.FormFieldPaginationResp = exports.FormsFieldsList = exports.FormField = exports.FORM_FIELD_SORT_KEY = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available sort keys for retrieving forms fields
 *
 * @generated from enum Scailo.FORM_FIELD_SORT_KEY
 */
var FORM_FIELD_SORT_KEY;
(function (FORM_FIELD_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: FORM_FIELD_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    FORM_FIELD_SORT_KEY[FORM_FIELD_SORT_KEY["FORM_FIELD_SORT_KEY_ID_UNSPECIFIED"] = 0] = "FORM_FIELD_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: FORM_FIELD_SORT_KEY_CREATED_AT = 1;
     */
    FORM_FIELD_SORT_KEY[FORM_FIELD_SORT_KEY["FORM_FIELD_SORT_KEY_CREATED_AT"] = 1] = "FORM_FIELD_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: FORM_FIELD_SORT_KEY_MODIFIED_AT = 2;
     */
    FORM_FIELD_SORT_KEY[FORM_FIELD_SORT_KEY["FORM_FIELD_SORT_KEY_MODIFIED_AT"] = 2] = "FORM_FIELD_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by name
     *
     * @generated from enum value: FORM_FIELD_SORT_KEY_NAME = 10;
     */
    FORM_FIELD_SORT_KEY[FORM_FIELD_SORT_KEY["FORM_FIELD_SORT_KEY_NAME"] = 10] = "FORM_FIELD_SORT_KEY_NAME";
    /**
     * Fetch ordered results by code
     *
     * @generated from enum value: FORM_FIELD_SORT_KEY_CODE = 11;
     */
    FORM_FIELD_SORT_KEY[FORM_FIELD_SORT_KEY["FORM_FIELD_SORT_KEY_CODE"] = 11] = "FORM_FIELD_SORT_KEY_CODE";
    /**
     * Fetch ordered results by the section ID, and the record ID (this is a composite sort key)
     *
     * @generated from enum value: FORM_FIELD_SORT_KEY_SECTION_ID_AND_RECORD_ID = 20;
     */
    FORM_FIELD_SORT_KEY[FORM_FIELD_SORT_KEY["FORM_FIELD_SORT_KEY_SECTION_ID_AND_RECORD_ID"] = 20] = "FORM_FIELD_SORT_KEY_SECTION_ID_AND_RECORD_ID";
})(FORM_FIELD_SORT_KEY || (exports.FORM_FIELD_SORT_KEY = FORM_FIELD_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(FORM_FIELD_SORT_KEY)
protobuf_1.proto3.util.setEnumType(FORM_FIELD_SORT_KEY, "Scailo.FORM_FIELD_SORT_KEY", [
    { no: 0, name: "FORM_FIELD_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "FORM_FIELD_SORT_KEY_CREATED_AT" },
    { no: 2, name: "FORM_FIELD_SORT_KEY_MODIFIED_AT" },
    { no: 10, name: "FORM_FIELD_SORT_KEY_NAME" },
    { no: 11, name: "FORM_FIELD_SORT_KEY_CODE" },
    { no: 20, name: "FORM_FIELD_SORT_KEY_SECTION_ID_AND_RECORD_ID" },
]);
/**
 *
 * Describes the data structure of each form field on the platform
 *
 * @generated from message Scailo.FormField
 */
class FormField extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this resource
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * The name of the form field
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The unique code by which the form field is classified
     *
     * @generated from field: string code = 21;
     */
    code = "";
    /**
     * The type of the form field
     *
     * @generated from field: Scailo.FORM_TYPE type = 11;
     */
    type = base_scailo_pb_js_1.FORM_TYPE.FORM_TYPE_ANY_UNSPECIFIED;
    /**
     * The ID of the corresponding form section that the form field belongs to
     *
     * @generated from field: uint64 section_id = 12;
     */
    sectionId = protobuf_1.protoInt64.zero;
    /**
     * The width of the form field
     *
     * @generated from field: string width = 13;
     */
    width = "";
    /**
     * The type of the element
     *
     * @generated from field: Scailo.FORM_FIELD_ELEMENT element = 14;
     */
    element = base_scailo_pb_js_1.FORM_FIELD_ELEMENT.FORM_FIELD_ELEMENT_ANY_UNSPECIFIED;
    /**
     * The placeholder of the form field
     *
     * @generated from field: string placeholder = 15;
     */
    placeholder = "";
    /**
     * The regex that is applicable on the form field
     *
     * @generated from field: string regex = 16;
     */
    regex = "";
    /**
     * The possible values that are applicable on the form field (in case of dropdowns, radio buttons, checkboxes)
     *
     * @generated from field: repeated string defined_values = 17;
     */
    definedValues = [];
    /**
     * Stores if the form field is printable
     *
     * @generated from field: bool printable = 18;
     */
    printable = false;
    /**
     * Stores the the form field needs to be highlighted
     *
     * @generated from field: bool highlightable = 19;
     */
    highlightable = false;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FormField";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "type", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.FORM_TYPE) },
        { no: 12, name: "section_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "width", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "element", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.FORM_FIELD_ELEMENT) },
        { no: 15, name: "placeholder", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "regex", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 17, name: "defined_values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
        { no: 18, name: "printable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 19, name: "highlightable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    ]);
    static fromBinary(bytes, options) {
        return new FormField().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FormField().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FormField().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FormField, a, b);
    }
}
exports.FormField = FormField;
/**
 *
 * Describes the data structure that stores a list of forms fields
 *
 * @generated from message Scailo.FormsFieldsList
 */
class FormsFieldsList extends protobuf_1.Message {
    /**
     * List of forms fields
     *
     * @generated from field: repeated Scailo.FormField list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FormsFieldsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: FormField, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new FormsFieldsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FormsFieldsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FormsFieldsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FormsFieldsList, a, b);
    }
}
exports.FormsFieldsList = FormsFieldsList;
/**
 *
 * Describes the data structure that responds to a pagination request
 *
 * @generated from message Scailo.FormFieldPaginationResp
 */
class FormFieldPaginationResp extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.FormField payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FormFieldPaginationResp";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: FormField, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new FormFieldPaginationResp().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FormFieldPaginationResp().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FormFieldPaginationResp().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FormFieldPaginationResp, a, b);
    }
}
exports.FormFieldPaginationResp = FormFieldPaginationResp;
/**
 *
 * Describes the necessary data structure during creation of a form field
 *
 * @generated from message Scailo.FormsFieldsServiceCreateRequest
 */
class FormsFieldsServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The name of the form field
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The unique code by which the form field is classified
     *
     * @generated from field: string code = 21;
     */
    code = "";
    /**
     * The type of the form field
     *
     * @generated from field: Scailo.FORM_TYPE type = 11;
     */
    type = base_scailo_pb_js_1.FORM_TYPE.FORM_TYPE_ANY_UNSPECIFIED;
    /**
     * The ID of the corresponding form section that the form field belongs to
     *
     * @generated from field: uint64 section_id = 12;
     */
    sectionId = protobuf_1.protoInt64.zero;
    /**
     * The width of the form field
     *
     * @generated from field: string width = 13;
     */
    width = "";
    /**
     * The type of the element
     *
     * @generated from field: Scailo.FORM_FIELD_ELEMENT element = 14;
     */
    element = base_scailo_pb_js_1.FORM_FIELD_ELEMENT.FORM_FIELD_ELEMENT_ANY_UNSPECIFIED;
    /**
     * The placeholder of the form field
     *
     * @generated from field: string placeholder = 15;
     */
    placeholder = "";
    /**
     * The regex that is applicable on the form field
     *
     * @generated from field: string regex = 16;
     */
    regex = "";
    /**
     * The possible values that are applicable on the form field (in case of dropdowns, radio buttons, checkboxes)
     *
     * @generated from field: repeated string defined_values = 17;
     */
    definedValues = [];
    /**
     * Stores if the form field is printable
     *
     * @generated from field: bool printable = 18;
     */
    printable = false;
    /**
     * Stores the the form field needs to be highlighted
     *
     * @generated from field: bool highlightable = 19;
     */
    highlightable = false;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FormsFieldsServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "type", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.FORM_TYPE) },
        { no: 12, name: "section_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "width", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "element", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.FORM_FIELD_ELEMENT) },
        { no: 15, name: "placeholder", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "regex", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 17, name: "defined_values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
        { no: 18, name: "printable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 19, name: "highlightable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    ]);
    static fromBinary(bytes, options) {
        return new FormsFieldsServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FormsFieldsServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FormsFieldsServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FormsFieldsServiceCreateRequest, a, b);
    }
}
exports.FormsFieldsServiceCreateRequest = FormsFieldsServiceCreateRequest;
/**
 *
 * Describes the data structure to perform the update (draft/revision) operation on a form field
 *
 * @generated from message Scailo.FormsFieldsServiceUpdateRequest
 */
class FormsFieldsServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the form field that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * The name of the form field
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The unique code by which the form field is classified
     *
     * @generated from field: string code = 21;
     */
    code = "";
    /**
     * The ID of the corresponding form section that the form field belongs to
     *
     * @generated from field: uint64 section_id = 12;
     */
    sectionId = protobuf_1.protoInt64.zero;
    /**
     * The width of the form field
     *
     * @generated from field: string width = 13;
     */
    width = "";
    /**
     * The placeholder of the form field
     *
     * @generated from field: string placeholder = 15;
     */
    placeholder = "";
    /**
     * The regex that is applicable on the form field
     *
     * @generated from field: string regex = 16;
     */
    regex = "";
    /**
     * The possible values that are applicable on the form field (in case of dropdowns, radio buttons, checkboxes)
     *
     * @generated from field: repeated string defined_values = 17;
     */
    definedValues = [];
    /**
     * Stores if the form field is printable
     *
     * @generated from field: bool printable = 18;
     */
    printable = false;
    /**
     * Stores the the form field needs to be highlighted
     *
     * @generated from field: bool highlightable = 19;
     */
    highlightable = false;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FormsFieldsServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "section_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "width", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 15, name: "placeholder", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "regex", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 17, name: "defined_values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
        { no: 18, name: "printable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 19, name: "highlightable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    ]);
    static fromBinary(bytes, options) {
        return new FormsFieldsServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FormsFieldsServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FormsFieldsServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FormsFieldsServiceUpdateRequest, a, b);
    }
}
exports.FormsFieldsServiceUpdateRequest = FormsFieldsServiceUpdateRequest;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.FormsFieldsServicePaginationReq
 */
class FormsFieldsServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.FORM_FIELD_SORT_KEY sort_key = 5;
     */
    sortKey = FORM_FIELD_SORT_KEY.FORM_FIELD_SORT_KEY_ID_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FormsFieldsServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(FORM_FIELD_SORT_KEY) },
    ]);
    static fromBinary(bytes, options) {
        return new FormsFieldsServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FormsFieldsServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FormsFieldsServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FormsFieldsServicePaginationReq, a, b);
    }
}
exports.FormsFieldsServicePaginationReq = FormsFieldsServicePaginationReq;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.FormsFieldsServiceFilterReq
 */
class FormsFieldsServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.FORM_FIELD_SORT_KEY sort_key = 5;
     */
    sortKey = FORM_FIELD_SORT_KEY.FORM_FIELD_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The name of the form field
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The type of the form field
     *
     * @generated from field: Scailo.FORM_TYPE type = 11;
     */
    type = base_scailo_pb_js_1.FORM_TYPE.FORM_TYPE_ANY_UNSPECIFIED;
    /**
     * The ID of the corresponding form section that the form field belongs to
     *
     * @generated from field: uint64 section_id = 12;
     */
    sectionId = protobuf_1.protoInt64.zero;
    /**
     * The unique code by which the form field is classified
     *
     * @generated from field: string code = 21;
     */
    code = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FormsFieldsServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(FORM_FIELD_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "type", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.FORM_TYPE) },
        { no: 12, name: "section_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new FormsFieldsServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FormsFieldsServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FormsFieldsServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FormsFieldsServiceFilterReq, a, b);
    }
}
exports.FormsFieldsServiceFilterReq = FormsFieldsServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.FormsFieldsServiceCountReq
 */
class FormsFieldsServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The name of the form field
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The type of the form field
     *
     * @generated from field: Scailo.FORM_TYPE type = 11;
     */
    type = base_scailo_pb_js_1.FORM_TYPE.FORM_TYPE_ANY_UNSPECIFIED;
    /**
     * The ID of the corresponding form section that the form field belongs to
     *
     * @generated from field: uint64 section_id = 12;
     */
    sectionId = protobuf_1.protoInt64.zero;
    /**
     * The unique code by which the form field is classified
     *
     * @generated from field: string code = 21;
     */
    code = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FormsFieldsServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "type", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.FORM_TYPE) },
        { no: 12, name: "section_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new FormsFieldsServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FormsFieldsServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FormsFieldsServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FormsFieldsServiceCountReq, a, b);
    }
}
exports.FormsFieldsServiceCountReq = FormsFieldsServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on forms fields
 *
 * @generated from message Scailo.FormsFieldsServiceSearchAllReq
 */
class FormsFieldsServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the response
     *
     * @generated from field: Scailo.FORM_FIELD_SORT_KEY sort_key = 5;
     */
    sortKey = FORM_FIELD_SORT_KEY.FORM_FIELD_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter forms fields
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 10;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FormsFieldsServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(FORM_FIELD_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new FormsFieldsServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FormsFieldsServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FormsFieldsServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FormsFieldsServiceSearchAllReq, a, b);
    }
}
exports.FormsFieldsServiceSearchAllReq = FormsFieldsServiceSearchAllReq;
