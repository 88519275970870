import { context } from "./../../router";
import { SalesReturn, SalesReturnsService, SalesReturnAncillaryParameters, Currency, Family, UnitOfMaterial, TaxGroup, SalesOrder, SalesOrdersService, SalesOrderAncillaryParameters, getClientForSalesReturnsService, getClientForClientsService, getClientForAssociatesService, getClientForUnitsOfMaterialsService, getClientForSalesOrdersService } from "@kernelminds/scailo-sdk";
import { emptyDiv, getLinkForSalesOrder, renderFilterPrimarySubSection, renderInput, renderPageTitleSection, renderSpan, returnFormFromFields } from "../../ui";
import { convertBigIntTimestampToDate, convertCentsToMoney, decodeSLC, downloadData, internationalizeMoney, randomId, returnAssociateBadge, toTitleCase } from "../../utilities";
import { PromiseClient, Transport } from "@connectrpc/connect";
import { familiesListFromIDs } from "../../fetches";
import { getTransport } from "../../clients";

const downloadPDFButtonClass = "__download-pdf-btn";
// const downloadCSVButtonClass = "__download-csv-btn";
const goToSalesOrderButtonClass = "__go-to-sales-order-btn";

export async function handleIndividualSalesReturn(ctx: context) {
    let content = <HTMLDivElement>document.getElementById("central-content");
    while (content.firstChild) {
        content.removeChild(content.firstChild);
    }
    const transport = getTransport();
    const accessClient = getClientForSalesReturnsService(transport);
    const [salesreturn, ancillaryParams] = await Promise.all([
        accessClient.viewByUUID({ uuid: ctx.params.uuid }),
        accessClient.viewAncillaryParametersByUUID({ uuid: ctx.params.uuid }),
    ]);

    document.title = salesreturn.approvalMetadata?.approvedOn! > 0 ? salesreturn.finalRefNumber : salesreturn.referenceId;

    let container = document.createElement("div");
    container.className = "overflow-x-auto";
    content.appendChild(container);

    const readonly = true;
    const { formGrid } = await getForm(salesreturn, ancillaryParams, readonly, accessClient, transport);

    container.appendChild(formGrid);

    // Setup PDF downloads
    let pdfDownloadButtons = container.getElementsByClassName(downloadPDFButtonClass);
    for (let i = 0; i < pdfDownloadButtons.length; i++) {
        let btn = <HTMLButtonElement>pdfDownloadButtons[i];
        btn.addEventListener("click", async evt => {
            evt.preventDefault();

            const originalButtonHTML = btn.innerHTML;
            btn.disabled = true;
            btn.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
            let file = await accessClient.downloadByUUID({ uuid: btn.getAttribute("data-uuid") || "" });

            btn.disabled = false;
            btn.innerHTML = originalButtonHTML;

            downloadData(file.content, "pdf", file.name.replace(".pdf", ""));
        });
    }

    // Setup CSV downloads
    // let csvDownloadButtons = container.getElementsByClassName(downloadCSVButtonClass);
    // for (let i = 0; i < csvDownloadButtons.length; i++) {
    //     let btn = <HTMLButtonElement>csvDownloadButtons[i];
    //     btn.addEventListener("click", async evt => {
    //         evt.preventDefault();

    //         const originalButtonHTML = btn.innerHTML;
    //         btn.disabled = true;
    //         btn.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
    //         let file = await accessClient.downloadAsCSV({ uuid: btn.getAttribute("data-uuid") || "" });

    //         btn.disabled = false;
    //         btn.innerHTML = originalButtonHTML;

    //         downloadData(file.content, "csv", file.name.replace(".csv", ""));
    //     });
    // }

}

async function renderReferencesSection(salesreturn: SalesReturn, ancillaryParams: SalesReturnAncillaryParameters, salesOrder: SalesOrder, readonly: boolean, accessClient: PromiseClient<typeof SalesReturnsService>): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "References", titleMdColSpan: 3 });

    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Initial Reference", inputType: "text", dataMapper: "referenceId", dataType: "string", value: salesreturn.referenceId, mdColSpan: 6, helpText: "Initial Reference of the record.", dataRegex: ".+" }));
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Final Reference", inputType: "text", dataMapper: "finalRefNumber", dataType: "string", value: salesreturn.finalRefNumber, mdColSpan: 6, helpText: "Final Reference of the record that is automatically generated.", dataRegex: ".+" }));
    // Display status as well
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Status", inputType: "text", dataMapper: "status", dataType: "string", value: toTitleCase(decodeSLC(salesreturn.status)), mdColSpan: 3, helpText: "Status of the record.", dataRegex: ".+" }));

    contentGrid.appendChild(emptyDiv());
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Sales Order Reference", inputType: "text", dataMapper: "refId", dataType: "string", value: salesOrder.approvalMetadata?.approvedOn! > 0 ? salesOrder.finalRefNumber : salesOrder.referenceId, mdColSpan: 6, helpText: "Reference of the Sales Order.", dataRegex: ".+" }));

    return grid;
}

async function renderBuyerAndConsigneeSection(salesreturn: SalesReturn, ancillaryParams: SalesOrderAncillaryParameters, readonly: boolean, accessClient: PromiseClient<typeof SalesReturnsService>, transport: Transport): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Buyer Information", titleMdColSpan: 3 });

    // Get buyer and consignee
    const clientsAccessClient = getClientForClientsService(transport);
    const [buyer, consignee] = await Promise.all([
        clientsAccessClient.viewByUUID({ uuid: ancillaryParams.buyerClientUuid }),
        clientsAccessClient.viewByUUID({ uuid: ancillaryParams.consigneeClientUuid }),
    ]);

    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Buyer", inputType: "text", dataMapper: "buyerClientId", dataType: "string", value: `${buyer.code.length > 0 ? `(${buyer.code})` : ''} ${buyer.name}`, mdColSpan: 6, helpText: "The information of the buyer.", dataRegex: ".+" }));
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Consignee", inputType: "text", dataMapper: "consigneeClientId", dataType: "string", value: `${consignee.code.length > 0 ? `(${consignee.code})` : ''} ${consignee.name}`, mdColSpan: 6, helpText: "The information of the consignee.", dataRegex: ".+" }));

    return grid;
}

async function renderDatesSection(salesreturn: SalesReturn, ancillaryParams: SalesReturnAncillaryParameters, salesOrder: SalesOrder, readonly: boolean, accessClient: PromiseClient<typeof SalesReturnsService>): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Important Dates", titleMdColSpan: 3 });

    // Dates (creation and approval)
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Sales Return Created On", inputType: "text", dataMapper: "createdAt", dataType: "string", value: convertBigIntTimestampToDate(salesreturn.metadata?.createdAt!), mdColSpan: 3, helpText: "The creation date of this Sales Return.", dataRegex: ".*" }));
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Sales Return Approved On", inputType: "text", dataMapper: "approvedOn", dataType: "string", value: convertBigIntTimestampToDate(salesreturn.approvalMetadata?.approvedOn!), mdColSpan: 3, helpText: "The approval date of this Sales Return.", dataRegex: ".*" }));

    contentGrid.appendChild(emptyDiv());

    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Sales Order Created On", inputType: "text", dataMapper: "createdAt", dataType: "string", value: convertBigIntTimestampToDate(salesOrder.metadata?.createdAt!), mdColSpan: 3, helpText: "The creation date of the associated Sales Order.", dataRegex: ".*" }));
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Sales Order Approved On", inputType: "text", dataMapper: "approvedOn", dataType: "string", value: convertBigIntTimestampToDate(salesOrder.approvalMetadata?.approvedOn!), mdColSpan: 3, helpText: "The approval date of the associated Sales Order.", dataRegex: ".*" }));

    return grid;
}

async function renderContactsSection(salesorder: SalesOrder, readonly: boolean, accessClient: PromiseClient<typeof SalesOrdersService>, transport: Transport): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Contacts", titleMdColSpan: 3 });

    const associatesClient = getClientForAssociatesService(transport);

    let contacts = (await accessClient.viewSalesOrderContacts({ uuid: salesorder.metadata?.uuid })).list;
    let salesOrderAssociates = await Promise.all(contacts.map(c => c.associateUuid).map(uuid => {
        return associatesClient.viewByUUID({ uuid });
    }));

    salesOrderAssociates.forEach(contact => {
        if (contact) {
            contentGrid.appendChild(returnAssociateBadge(contact));
        }
    });

    return grid;
}

async function renderDynamicFormsSection(salesreturn: SalesReturn, readonly: boolean, accessClient: PromiseClient<typeof SalesReturnsService>): Promise<HTMLDivElement> {
    let container = document.createElement("div");

    if (salesreturn.formData.length == 0) {
        return container;
    }

    let containerTitle = document.createElement("div");
    containerTitle.className = "rounded-t mb-0 px-0 border-0";
    containerTitle.appendChild(renderPageTitleSection({ title: `Other Details` }));
    container.appendChild(containerTitle);

    let formFields = await returnFormFromFields(salesreturn.formData, "sales-returns", true, readonly);

    container.appendChild(formFields);

    return container;
}

async function renderFamiliesSection(salesreturn: SalesReturn, readonly: boolean, accessClient: PromiseClient<typeof SalesReturnsService>, transport: Transport): Promise<HTMLDivElement> {
    let container = document.createElement("div");

    let containerTitle = document.createElement("div");
    containerTitle.className = "rounded-t mb-0 px-0 border-0";
    containerTitle.appendChild(renderPageTitleSection({ title: `Line Items` }));

    container.appendChild(containerTitle);

    let familiesMap: Map<bigint, Family> = new Map();
    (await familiesListFromIDs(Array.from(new Set(salesreturn.list.map(p => p.familyId))))).forEach(family => {
        familiesMap.set(family.metadata?.id!, family);
    });
    
    const uomClient = getClientForUnitsOfMaterialsService(transport);
    let unitsList = salesreturn.list.map(p => p.clientUomId);
    familiesMap.forEach(family => {
        unitsList.push(family.uomId);
    });

    let uomsList = await Promise.all(Array.from(new Set(unitsList)).map(id => {
        return uomClient.viewByID({ id });
    }));
    let uomsMap: Map<bigint, UnitOfMaterial> = new Map();

    uomsList.forEach(uom => {
        uomsMap.set(uom.metadata!.id, uom);
    });

    salesreturn.list.forEach((item, index) => {
        let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: `${index + 1}.`, titleMdColSpan: 1 });

        let fam = familiesMap.get(item.familyId) || new Family();

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Material Code", value: `${fam.code}`, mdColSpan: 3, helpText: `The code of the material` }));
        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Material Name", value: `${fam.name}`, mdColSpan: 9, helpText: `The name of the material` }));

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Client Quantity & UoM", value: `${internationalizeMoney(parseFloat(convertCentsToMoney(item.clientQuantity)))} (${uomsMap.get(item.clientUomId)?.symbol}) ${uomsMap.get(item.clientUomId)?.name}`, mdColSpan: 3, helpText: `The quantity of the material in Client's unit of material` }));

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Buyer Quantity & UoM", value: `${internationalizeMoney(parseFloat(convertCentsToMoney(item.internalQuantity)))} (${uomsMap.get(fam.uomId)?.symbol}) ${uomsMap.get(fam.uomId)?.name}`, mdColSpan: 3, helpText: `The quantity of the material in Buyer's unit of material` }));

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "HSN/SAC Code", value: `${fam.hsnSacCode}`, mdColSpan: 3, helpText: `The HSN/SAC code of the material` }));

        container.appendChild(grid);

        let hr = document.createElement("hr");
        hr.classList.add("m-5");
        container.appendChild(hr);
    });

    return container;
}

async function getForm(salesreturn: SalesReturn, ancillaryParams: SalesReturnAncillaryParameters, readonly: boolean, accessClient: PromiseClient<typeof SalesReturnsService>, transport: Transport) {
    let formGrid = document.createElement("div");
    formGrid.className = "grid grid-cols-1 gap-6 mb-6";
    const formId = randomId();

    const salesOrdersClient = getClientForSalesOrdersService(transport);
    const [salesOrder, salesOrderAncillaryParams] = await Promise.all([
        salesOrdersClient.viewByUUID({ uuid: ancillaryParams.refUuid }),
        salesOrdersClient.viewAncillaryParametersByUUID({ uuid: ancillaryParams.refUuid }),
    ]);

    const [
        referencesSection,
        buyerAndConsigneeSection,
        datesSection,
        contactsSection,
        dynamicFormsSection,
        familiesSection,
    ] = await Promise.all([
        renderReferencesSection(salesreturn, ancillaryParams, salesOrder, readonly, accessClient),
        renderBuyerAndConsigneeSection(salesreturn, salesOrderAncillaryParams, readonly, accessClient, transport),
        renderDatesSection(salesreturn, ancillaryParams, salesOrder, readonly, accessClient),
        renderContactsSection(salesOrder, readonly, salesOrdersClient, transport),
        renderDynamicFormsSection(salesreturn, readonly, accessClient),
        renderFamiliesSection(salesreturn, readonly, accessClient, transport)
    ]);

    let buttonContainer = document.createElement("div");
    buttonContainer.classList.add("col-span-12", "flex", "justify-center", "overflow-x-auto");

    // Download PDF button
    let downloadPDFButton = document.createElement("button");
    downloadPDFButton.id = randomId();
    downloadPDFButton.className = `btn btn-success btn-outline btn-sm ${downloadPDFButtonClass} mr-4`;
    downloadPDFButton.innerText = "Download PDF";
    downloadPDFButton.setAttribute("data-uuid", salesreturn.metadata?.uuid!);
    downloadPDFButton.setAttribute("data-name", salesreturn.approvalMetadata?.approvedOn! > 0 ? salesreturn.finalRefNumber : salesreturn.referenceId);
    buttonContainer.appendChild(downloadPDFButton);

    // Download CSV button
    // let downloadCSVButton = document.createElement("button");
    // downloadCSVButton.id = randomId();
    // downloadCSVButton.className = `btn btn-success btn-outline btn-sm ${downloadCSVButtonClass} mr-4`;
    // downloadCSVButton.innerText = "Download CSV";
    // downloadCSVButton.setAttribute("data-uuid", salesreturn.metadata?.uuid!);
    // downloadCSVButton.setAttribute("data-name", salesreturn.approvalMetadata?.approvedOn! > 0 ? salesreturn.finalRefNumber : salesreturn.referenceId);
    // buttonContainer.appendChild(downloadCSVButton);

    let goToSalesOrderButton = document.createElement("a");
    goToSalesOrderButton.className = `btn btn-info btn-outline btn-sm ${goToSalesOrderButtonClass} mr-4`;
    goToSalesOrderButton.innerText = "Go To Sales Order";
    goToSalesOrderButton.href = getLinkForSalesOrder(salesOrder.metadata?.uuid!)
    buttonContainer.appendChild(goToSalesOrderButton);

    formGrid.innerHTML = `
        <div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f5f8f3] w-full shadow-lg rounded">
            <div class="rounded-t mb-0 px-0 border-0">
                ${renderPageTitleSection({ title: `Sales Return: ` + document.title }).outerHTML}
                ${buttonContainer.outerHTML}
            </div>
            <form id="${formId}">
                <hr class="m-5">
                ${referencesSection.outerHTML}
                <hr class="m-5">
                ${buyerAndConsigneeSection.outerHTML}
                <hr class="m-5">
                ${datesSection.outerHTML}
                <hr class="m-5">
                ${contactsSection.outerHTML}
                <hr class="m-5">
            </form>
        </div>
        ${dynamicFormsSection.childElementCount > 0 ? 
            `<div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f5f8f3] w-full shadow-lg rounded">
                ${dynamicFormsSection.outerHTML}
            </div>` 
        : ''}
        <div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f5f8f3] w-full shadow-lg rounded">
            ${familiesSection.outerHTML}
        </div>
    `;

    return { formGrid }
}
