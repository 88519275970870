"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file equations_families.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.EquationsFamiliesService = void 0;
const equations_families_scailo_pb_js_1 = require("./equations_families.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
const families_scailo_pb_js_1 = require("./families.scailo_pb.js");
/**
 *
 * Describes the common methods applicable on each equation family
 *
 * @generated from service Scailo.EquationsFamiliesService
 */
exports.EquationsFamiliesService = {
    typeName: "Scailo.EquationsFamiliesService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.EquationsFamiliesService.Create
         */
        create: {
            name: "Create",
            I: equations_families_scailo_pb_js_1.EquationsFamiliesServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.EquationsFamiliesService.Draft
         */
        draft: {
            name: "Draft",
            I: equations_families_scailo_pb_js_1.EquationsFamiliesServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.EquationsFamiliesService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: equations_families_scailo_pb_js_1.EquationsFamiliesServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.EquationsFamiliesService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.EquationsFamiliesService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.EquationsFamiliesService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.EquationsFamiliesService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.EquationsFamiliesService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: equations_families_scailo_pb_js_1.EquationsFamiliesServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.EquationsFamiliesService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.EquationsFamiliesService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.EquationsFamiliesService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.EquationsFamiliesService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.EquationsFamiliesService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.EquationsFamiliesService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * Send Email
         * rpc SendEmail (IdentifierWithEmailAttributes) returns (IdentifierResponse);
         *
         * @generated from rpc Scailo.EquationsFamiliesService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Clone equation from an existing equation (denoted by the identifier)
         *
         * @generated from rpc Scailo.EquationsFamiliesService.Clone
         */
        clone: {
            name: "Clone",
            I: base_scailo_pb_js_1.CloneRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an item to a equation family
         *
         * @generated from rpc Scailo.EquationsFamiliesService.AddEquationFamilyItem
         */
        addEquationFamilyItem: {
            name: "AddEquationFamilyItem",
            I: equations_families_scailo_pb_js_1.EquationsFamiliesServiceItemCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify an item in a equation family
         *
         * @generated from rpc Scailo.EquationsFamiliesService.ModifyEquationFamilyItem
         */
        modifyEquationFamilyItem: {
            name: "ModifyEquationFamilyItem",
            I: equations_families_scailo_pb_js_1.EquationsFamiliesServiceItemUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve an item in a equation family
         *
         * @generated from rpc Scailo.EquationsFamiliesService.ApproveEquationFamilyItem
         */
        approveEquationFamilyItem: {
            name: "ApproveEquationFamilyItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an item in a equation family
         *
         * @generated from rpc Scailo.EquationsFamiliesService.DeleteEquationFamilyItem
         */
        deleteEquationFamilyItem: {
            name: "DeleteEquationFamilyItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder items in a equation family
         *
         * @generated from rpc Scailo.EquationsFamiliesService.ReorderEquationFamilyItems
         */
        reorderEquationFamilyItems: {
            name: "ReorderEquationFamilyItems",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Equation Family Item by ID
         *
         * @generated from rpc Scailo.EquationsFamiliesService.ViewEquationFamilyItemByID
         */
        viewEquationFamilyItemByID: {
            name: "ViewEquationFamilyItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: equations_families_scailo_pb_js_1.EquationFamilyItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved equation family items for given equation family ID
         *
         * @generated from rpc Scailo.EquationsFamiliesService.ViewApprovedEquationFamilyItems
         */
        viewApprovedEquationFamilyItems: {
            name: "ViewApprovedEquationFamilyItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: equations_families_scailo_pb_js_1.EquationsFamiliesItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved equation family items for given equation family ID
         *
         * @generated from rpc Scailo.EquationsFamiliesService.ViewUnapprovedEquationFamilyItems
         */
        viewUnapprovedEquationFamilyItems: {
            name: "ViewUnapprovedEquationFamilyItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: equations_families_scailo_pb_js_1.EquationsFamiliesItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the equation family item
         *
         * @generated from rpc Scailo.EquationsFamiliesService.ViewEquationFamilyItemHistory
         */
        viewEquationFamilyItemHistory: {
            name: "ViewEquationFamilyItemHistory",
            I: equations_families_scailo_pb_js_1.EquationFamilyItemHistoryRequest,
            O: equations_families_scailo_pb_js_1.EquationsFamiliesItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved equation family items for given equation family ID with pagination
         *
         * @generated from rpc Scailo.EquationsFamiliesService.ViewPaginatedApprovedEquationFamilyItems
         */
        viewPaginatedApprovedEquationFamilyItems: {
            name: "ViewPaginatedApprovedEquationFamilyItems",
            I: equations_families_scailo_pb_js_1.EquationFamilyItemsSearchRequest,
            O: equations_families_scailo_pb_js_1.EquationsFamiliesServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved equation family items for given equation family ID with pagination
         *
         * @generated from rpc Scailo.EquationsFamiliesService.ViewPaginatedUnapprovedEquationFamilyItems
         */
        viewPaginatedUnapprovedEquationFamilyItems: {
            name: "ViewPaginatedUnapprovedEquationFamilyItems",
            I: equations_families_scailo_pb_js_1.EquationFamilyItemsSearchRequest,
            O: equations_families_scailo_pb_js_1.EquationsFamiliesServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through equation family items with pagination
         *
         * @generated from rpc Scailo.EquationsFamiliesService.SearchItemsWithPagination
         */
        searchItemsWithPagination: {
            name: "SearchItemsWithPagination",
            I: equations_families_scailo_pb_js_1.EquationFamilyItemsSearchRequest,
            O: equations_families_scailo_pb_js_1.EquationsFamiliesServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * CSV operations
         * Download the CSV file that could be used to upload items from the filled CSV file. The same file can also be used as a template when there are no existing records
         *
         * @generated from rpc Scailo.EquationsFamiliesService.DownloadItemsAsCSV
         */
        downloadItemsAsCSV: {
            name: "DownloadItemsAsCSV",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download the CSV file with the entire dependency tree. Useful for identifying quantities necessary from all the dependencies.
         *
         * @generated from rpc Scailo.EquationsFamiliesService.DownloadTreeAsCSV
         */
        downloadTreeAsCSV: {
            name: "DownloadTreeAsCSV",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Upload items using a CSV file. This is an idempotent operation. All the existing items are deleted before adding the items from the file.
         *
         * @generated from rpc Scailo.EquationsFamiliesService.UploadEquationFamilyItems
         */
        uploadEquationFamilyItems: {
            name: "UploadEquationFamilyItems",
            I: base_scailo_pb_js_1.IdentifierUUIDWithFile,
            O: base_scailo_pb_js_1.IdentifiersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.EquationsFamiliesService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: equations_families_scailo_pb_js_1.EquationFamily,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.EquationsFamiliesService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: equations_families_scailo_pb_js_1.EquationsFamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.EquationsFamiliesService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: equations_families_scailo_pb_js_1.EquationsFamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.EquationsFamiliesService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: equations_families_scailo_pb_js_1.EquationsFamiliesServicePaginationReq,
            O: equations_families_scailo_pb_js_1.EquationsFamiliesServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the latest equation for a family (denoted by the given identifier)
         *
         * @generated from rpc Scailo.EquationsFamiliesService.ViewForFamilyID
         */
        viewForFamilyID: {
            name: "ViewForFamilyID",
            I: base_scailo_pb_js_1.Identifier,
            O: equations_families_scailo_pb_js_1.EquationFamily,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all selectable families for a family ID represented by Identifier (without cyclical dependencies) and the given search key
         *
         * @generated from rpc Scailo.EquationsFamiliesService.ViewSelectableFamilies
         */
        viewSelectableFamilies: {
            name: "ViewSelectableFamilies",
            I: base_scailo_pb_js_1.IdentifierWithSearch,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.EquationsFamiliesService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: equations_families_scailo_pb_js_1.EquationsFamiliesServiceSearchAllReq,
            O: equations_families_scailo_pb_js_1.EquationsFamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.EquationsFamiliesService.Filter
         */
        filter: {
            name: "Filter",
            I: equations_families_scailo_pb_js_1.EquationsFamiliesServiceFilterReq,
            O: equations_families_scailo_pb_js_1.EquationsFamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.EquationsFamiliesService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given count criteria
         *
         * @generated from rpc Scailo.EquationsFamiliesService.Count
         */
        count: {
            name: "Count",
            I: equations_families_scailo_pb_js_1.EquationsFamiliesServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
