"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file inventory.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConsolidatedInventoryStatistics = exports.ReturnableInventorySearchReq = exports.InventoryServiceFamilyQuantityReq = exports.InventoryHashSearchReq = exports.IssuableInventorySearchReq = exports.InventoryCodeMap = exports.GenericInventoryList = exports.GenericInventory = exports.GENERIC_INVENTORY_REF_FROM = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const families_scailo_pb_js_1 = require("./families.scailo_pb.js");
/**
 *
 * Stores all the possible references from which a generic inventory can be added
 *
 * @generated from enum Scailo.GENERIC_INVENTORY_REF_FROM
 */
var GENERIC_INVENTORY_REF_FROM;
(function (GENERIC_INVENTORY_REF_FROM) {
    /**
     * Used only in filters
     *
     * @generated from enum value: GENERIC_INVENTORY_REF_FROM_ANY_UNSPECIFIED = 0;
     */
    GENERIC_INVENTORY_REF_FROM[GENERIC_INVENTORY_REF_FROM["GENERIC_INVENTORY_REF_FROM_ANY_UNSPECIFIED"] = 0] = "GENERIC_INVENTORY_REF_FROM_ANY_UNSPECIFIED";
    /**
     * Denotes that the component originated from initial stock
     *
     * @generated from enum value: GENERIC_INVENTORY_REF_FROM_INITIAL_STOCK = 1;
     */
    GENERIC_INVENTORY_REF_FROM[GENERIC_INVENTORY_REF_FROM["GENERIC_INVENTORY_REF_FROM_INITIAL_STOCK"] = 1] = "GENERIC_INVENTORY_REF_FROM_INITIAL_STOCK";
    /**
     * Denotes that the component originated from a goods receipt
     *
     * @generated from enum value: GENERIC_INVENTORY_REF_FROM_GOODS_RECEIPT = 2;
     */
    GENERIC_INVENTORY_REF_FROM[GENERIC_INVENTORY_REF_FROM["GENERIC_INVENTORY_REF_FROM_GOODS_RECEIPT"] = 2] = "GENERIC_INVENTORY_REF_FROM_GOODS_RECEIPT";
    /**
     * Denotes that the component originated from an inward job free issue material
     *
     * @generated from enum value: GENERIC_INVENTORY_REF_FROM_INWARD_JOB_FREE_ISSUE_MATERIAL = 3;
     */
    GENERIC_INVENTORY_REF_FROM[GENERIC_INVENTORY_REF_FROM["GENERIC_INVENTORY_REF_FROM_INWARD_JOB_FREE_ISSUE_MATERIAL"] = 3] = "GENERIC_INVENTORY_REF_FROM_INWARD_JOB_FREE_ISSUE_MATERIAL";
    /**
     * Denotes that the component originated from an outward job free issue material
     *
     * @generated from enum value: GENERIC_INVENTORY_REF_FROM_OUTWARD_JOB_FREE_ISSUE_MATERIAL = 4;
     */
    GENERIC_INVENTORY_REF_FROM[GENERIC_INVENTORY_REF_FROM["GENERIC_INVENTORY_REF_FROM_OUTWARD_JOB_FREE_ISSUE_MATERIAL"] = 4] = "GENERIC_INVENTORY_REF_FROM_OUTWARD_JOB_FREE_ISSUE_MATERIAL";
    /**
     * Denotes that the component originated from a production plan
     *
     * @generated from enum value: GENERIC_INVENTORY_REF_FROM_PRODUCTION_PLAN = 5;
     */
    GENERIC_INVENTORY_REF_FROM[GENERIC_INVENTORY_REF_FROM["GENERIC_INVENTORY_REF_FROM_PRODUCTION_PLAN"] = 5] = "GENERIC_INVENTORY_REF_FROM_PRODUCTION_PLAN";
})(GENERIC_INVENTORY_REF_FROM || (exports.GENERIC_INVENTORY_REF_FROM = GENERIC_INVENTORY_REF_FROM = {}));
// Retrieve enum metadata with: proto3.getEnumType(GENERIC_INVENTORY_REF_FROM)
protobuf_1.proto3.util.setEnumType(GENERIC_INVENTORY_REF_FROM, "Scailo.GENERIC_INVENTORY_REF_FROM", [
    { no: 0, name: "GENERIC_INVENTORY_REF_FROM_ANY_UNSPECIFIED" },
    { no: 1, name: "GENERIC_INVENTORY_REF_FROM_INITIAL_STOCK" },
    { no: 2, name: "GENERIC_INVENTORY_REF_FROM_GOODS_RECEIPT" },
    { no: 3, name: "GENERIC_INVENTORY_REF_FROM_INWARD_JOB_FREE_ISSUE_MATERIAL" },
    { no: 4, name: "GENERIC_INVENTORY_REF_FROM_OUTWARD_JOB_FREE_ISSUE_MATERIAL" },
    { no: 5, name: "GENERIC_INVENTORY_REF_FROM_PRODUCTION_PLAN" },
]);
/**
 *
 * Describes the parameters that are present in a generic inventory payload, which is an abstraction over every inventory item that consists of the common parameters
 *
 * @generated from message Scailo.GenericInventory
 */
class GenericInventory extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this product
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * The status of this product
     *
     * @generated from field: Scailo.INVENTORY_LIFECYCLE status = 4;
     */
    status = base_scailo_pb_js_1.INVENTORY_LIFECYCLE.INVENTORY_LIFECYCLE_ANY_UNSPECIFIED;
    /**
     * The timestamp of when the item was admitted into store
     *
     * @generated from field: uint64 store_intake_at = 10;
     */
    storeIntakeAt = protobuf_1.protoInt64.zero;
    /**
     * The timestamp of when the item was either consumed or rejected
     *
     * @generated from field: uint64 consumed_or_rejected_at = 11;
     */
    consumedOrRejectedAt = protobuf_1.protoInt64.zero;
    /**
     * The timestamp of when the rework process began
     *
     * @generated from field: uint64 rework_start_at = 12;
     */
    reworkStartAt = protobuf_1.protoInt64.zero;
    /**
     * The timestamp of when the rework process ended
     *
     * @generated from field: uint64 rework_end_at = 13;
     */
    reworkEndAt = protobuf_1.protoInt64.zero;
    /**
     * The timestamp of when the item was either returned or scrapped
     *
     * @generated from field: uint64 returned_or_scrapped_at = 14;
     */
    returnedOrScrappedAt = protobuf_1.protoInt64.zero;
    /**
     * The timestamp of when the item was discarded
     *
     * @generated from field: uint64 discarded_at = 15;
     */
    discardedAt = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 18;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the parent inventory item (if applicable)
     *
     * @generated from field: uint64 parent_ref_id = 30;
     */
    parentRefId = protobuf_1.protoInt64.zero;
    /**
     * Stores the reference from
     *
     * @generated from field: Scailo.GENERIC_INVENTORY_REF_FROM ref_from = 31;
     */
    refFrom = GENERIC_INVENTORY_REF_FROM.GENERIC_INVENTORY_REF_FROM_ANY_UNSPECIFIED;
    /**
     * Stores the ID of the reference
     *
     * @generated from field: uint64 ref_id = 32;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the associated family
     *
     * @generated from field: uint64 family_id = 33;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The auto generated code of the product
     *
     * @generated from field: string code = 34;
     */
    code = "";
    /**
     * Stores the internal code (as given by user)
     *
     * @generated from field: string internal_item_code = 35;
     */
    internalItemCode = "";
    /**
     * The computed hash of the product
     *
     * @generated from field: string hash = 36;
     */
    hash = "";
    /**
     * Stores the quantity (in cents)
     *
     * @generated from field: uint64 quantity = 37;
     */
    quantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the remaining quantity (in cents)
     *
     * @generated from field: uint64 quantity_remaining = 38;
     */
    quantityRemaining = protobuf_1.protoInt64.zero;
    /**
     * Stores an optional secondary unit of product
     *
     * @generated from field: uint64 secondary_uom_id = 39;
     */
    secondaryUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores an optional quantity in the secondary unit of product
     *
     * @generated from field: uint64 secondary_quantity = 40;
     */
    secondaryQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores an optional shelf life as a timestamp
     *
     * @generated from field: uint64 shelf_life_timestamp = 41;
     */
    shelfLifeTimestamp = protobuf_1.protoInt64.zero;
    /**
     * Stores an optional warranty as a timestamp
     *
     * @generated from field: uint64 warranty_timestamp = 42;
     */
    warrantyTimestamp = protobuf_1.protoInt64.zero;
    /**
     * Stores the store to which the product is sent to
     *
     * @generated from field: uint64 store_id = 50;
     */
    storeId = protobuf_1.protoInt64.zero;
    /**
     * Stores an optional ID of the associated storage that the product is stored in
     *
     * @generated from field: uint64 storage_id = 51;
     */
    storageId = protobuf_1.protoInt64.zero;
    /**
     * Stores if the associated QC report should be public
     *
     * @generated from field: bool is_qc_report_public = 52;
     */
    isQcReportPublic = false;
    /**
     * The location ID of where the item needs to be stored
     *
     * @generated from field: uint64 location_id = 54;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * Stores any applicable remaining dimensions as a string
     *
     * @generated from field: string remaining_dimensions = 55;
     */
    remainingDimensions = "";
    /**
     * The description of the product
     *
     * @generated from field: string description = 60;
     */
    description = "";
    /**
     * The short URL of the item
     *
     * @generated from field: string short_url = 70;
     */
    shortUrl = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GenericInventory";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.INVENTORY_LIFECYCLE) },
        { no: 10, name: "store_intake_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "consumed_or_rejected_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "rework_start_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "rework_end_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "returned_or_scrapped_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "discarded_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "parent_ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 31, name: "ref_from", kind: "enum", T: protobuf_1.proto3.getEnumType(GENERIC_INVENTORY_REF_FROM) },
        { no: 32, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 33, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 34, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 35, name: "internal_item_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 36, name: "hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 37, name: "quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 38, name: "quantity_remaining", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 39, name: "secondary_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "secondary_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 41, name: "shelf_life_timestamp", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 42, name: "warranty_timestamp", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 50, name: "store_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 51, name: "storage_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 52, name: "is_qc_report_public", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 54, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 55, name: "remaining_dimensions", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 60, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 70, name: "short_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new GenericInventory().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GenericInventory().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GenericInventory().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GenericInventory, a, b);
    }
}
exports.GenericInventory = GenericInventory;
/**
 *
 * Describes the message consisting of the list of generic inventory
 *
 * @generated from message Scailo.GenericInventoryList
 */
class GenericInventoryList extends protobuf_1.Message {
    /**
     * List of generic inventory
     *
     * @generated from field: repeated Scailo.GenericInventory list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GenericInventoryList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: GenericInventory, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new GenericInventoryList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GenericInventoryList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GenericInventoryList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GenericInventoryList, a, b);
    }
}
exports.GenericInventoryList = GenericInventoryList;
/**
 *
 * Describes the parameters that are present in an inventory code map
 *
 * @generated from message Scailo.InventoryCodeMap
 */
class InventoryCodeMap extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this product
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the purpose
     *
     * @generated from field: string purpose = 10;
     */
    purpose = "";
    /**
     * The type of the family
     *
     * @generated from field: Scailo.FAMILY_TYPE family_type = 11;
     */
    familyType = families_scailo_pb_js_1.FAMILY_TYPE.FAMILY_TYPE_ANY_UNSPECIFIED;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 12;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores the inventory item's code
     *
     * @generated from field: string code = 20;
     */
    code = "";
    /**
     * The hash of the item
     *
     * @generated from field: string hash = 21;
     */
    hash = "";
    /**
     * The short URL of the item
     *
     * @generated from field: string short_url = 30;
     */
    shortUrl = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InventoryCodeMap";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 10, name: "purpose", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "family_type", kind: "enum", T: protobuf_1.proto3.getEnumType(families_scailo_pb_js_1.FAMILY_TYPE) },
        { no: 12, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "short_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new InventoryCodeMap().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InventoryCodeMap().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InventoryCodeMap().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InventoryCodeMap, a, b);
    }
}
exports.InventoryCodeMap = InventoryCodeMap;
/**
 *
 * Describes the message that consists of parameters that are required to retrieve issuable inventory
 *
 * @generated from message Scailo.IssuableInventorySearchReq
 */
class IssuableInventorySearchReq extends protobuf_1.Message {
    /**
     * Stores the status of the inventory
     *
     * @generated from field: Scailo.INVENTORY_LIFECYCLE status = 1;
     */
    status = base_scailo_pb_js_1.INVENTORY_LIFECYCLE.INVENTORY_LIFECYCLE_ANY_UNSPECIFIED;
    /**
     * Stores the search key
     *
     * @generated from field: string search_key = 5;
     */
    searchKey = "";
    /**
     * Stores the ID of the family that needs to be retrieved
     *
     * @generated from field: uint64 family_id = 10;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the location from where the inventory needs to be retrieved
     *
     * @generated from field: uint64 location_id = 15;
     */
    locationId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.IssuableInventorySearchReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.INVENTORY_LIFECYCLE) },
        { no: 5, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new IssuableInventorySearchReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new IssuableInventorySearchReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new IssuableInventorySearchReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(IssuableInventorySearchReq, a, b);
    }
}
exports.IssuableInventorySearchReq = IssuableInventorySearchReq;
/**
 *
 * Describes the request to search for an inventory item using the item's hash
 *
 * @generated from message Scailo.InventoryHashSearchReq
 */
class InventoryHashSearchReq extends protobuf_1.Message {
    /**
     * The hash of the item
     *
     * @generated from field: string hash = 1;
     */
    hash = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InventoryHashSearchReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new InventoryHashSearchReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InventoryHashSearchReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InventoryHashSearchReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InventoryHashSearchReq, a, b);
    }
}
exports.InventoryHashSearchReq = InventoryHashSearchReq;
/**
 *
 * Describes the request to calculate the quantity remaining for the given family in a particular status
 *
 * @generated from message Scailo.InventoryServiceFamilyQuantityReq
 */
class InventoryServiceFamilyQuantityReq extends protobuf_1.Message {
    /**
     * The status of this inventory item
     *
     * @generated from field: Scailo.INVENTORY_LIFECYCLE status = 10;
     */
    status = base_scailo_pb_js_1.INVENTORY_LIFECYCLE.INVENTORY_LIFECYCLE_ANY_UNSPECIFIED;
    /**
     * Stores the ID of the associated family
     *
     * @generated from field: uint64 family_id = 20;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InventoryServiceFamilyQuantityReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.INVENTORY_LIFECYCLE) },
        { no: 20, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new InventoryServiceFamilyQuantityReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InventoryServiceFamilyQuantityReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InventoryServiceFamilyQuantityReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InventoryServiceFamilyQuantityReq, a, b);
    }
}
exports.InventoryServiceFamilyQuantityReq = InventoryServiceFamilyQuantityReq;
/**
 *
 * Describes the message that consists of parameters that are required to retrieve returnable inventory
 *
 * @generated from message Scailo.ReturnableInventorySearchReq
 */
class ReturnableInventorySearchReq extends protobuf_1.Message {
    /**
     * Stores the ID of the reference (such as purchase order, sales order, inward job, outward job, stock issuance)
     *
     * @generated from field: uint64 ref_id = 1;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * Stores the search key
     *
     * @generated from field: string search_key = 5;
     */
    searchKey = "";
    /**
     * Stores the ID of the family that needs to be retrieved
     *
     * @generated from field: uint64 family_id = 10;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the location from where the inventory needs to be retrieved
     *
     * @generated from field: uint64 location_id = 15;
     */
    locationId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ReturnableInventorySearchReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 5, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ReturnableInventorySearchReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ReturnableInventorySearchReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ReturnableInventorySearchReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ReturnableInventorySearchReq, a, b);
    }
}
exports.ReturnableInventorySearchReq = ReturnableInventorySearchReq;
/**
 *
 * Describes the message consisting of all the inventory statistics for the given family ID
 *
 * @generated from message Scailo.ConsolidatedInventoryStatistics
 */
class ConsolidatedInventoryStatistics extends protobuf_1.Message {
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 1;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The base demand quantity
     *
     * @generated from field: int64 base_demand_count = 10;
     */
    baseDemandCount = protobuf_1.protoInt64.zero;
    /**
     * The work in progress quantity
     *
     * @generated from field: int64 work_in_progress_count = 20;
     */
    workInProgressCount = protobuf_1.protoInt64.zero;
    /**
     * The indented quantity
     *
     * @generated from field: int64 indented_count = 30;
     */
    indentedCount = protobuf_1.protoInt64.zero;
    /**
     * The ordered quantity
     *
     * @generated from field: int64 ordered_count = 40;
     */
    orderedCount = protobuf_1.protoInt64.zero;
    /**
     * The quantity in QC
     *
     * @generated from field: int64 qc_count = 100;
     */
    qcCount = protobuf_1.protoInt64.zero;
    /**
     * The quantity that has been rejected
     *
     * @generated from field: int64 rejected_count = 110;
     */
    rejectedCount = protobuf_1.protoInt64.zero;
    /**
     * The quantity that has been marked for return
     *
     * @generated from field: int64 returnable_count = 120;
     */
    returnableCount = protobuf_1.protoInt64.zero;
    /**
     * The quantity in rework
     *
     * @generated from field: int64 rework_count = 130;
     */
    reworkCount = protobuf_1.protoInt64.zero;
    /**
     * The quantity that has been scrapped
     *
     * @generated from field: int64 scrap_count = 140;
     */
    scrapCount = protobuf_1.protoInt64.zero;
    /**
     * The quantity in store
     *
     * @generated from field: int64 store_count = 150;
     */
    storeCount = protobuf_1.protoInt64.zero;
    /**
     * The quantity that is required
     *
     * @generated from field: int64 required_count = 200;
     */
    requiredCount = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ConsolidatedInventoryStatistics";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "base_demand_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 20, name: "work_in_progress_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 30, name: "indented_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 40, name: "ordered_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 100, name: "qc_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 110, name: "rejected_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 120, name: "returnable_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 130, name: "rework_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 140, name: "scrap_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 150, name: "store_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 200, name: "required_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ConsolidatedInventoryStatistics().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ConsolidatedInventoryStatistics().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ConsolidatedInventoryStatistics().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ConsolidatedInventoryStatistics, a, b);
    }
}
exports.ConsolidatedInventoryStatistics = ConsolidatedInventoryStatistics;
