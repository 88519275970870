"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file outward_jobs.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.OutwardJobsService = void 0;
const outward_jobs_scailo_pb_js_1 = require("./outward_jobs.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
const families_scailo_pb_js_1 = require("./families.scailo_pb.js");
/**
 *
 * Describes the common methods applicable on each outward job
 *
 * @generated from service Scailo.OutwardJobsService
 */
exports.OutwardJobsService = {
    typeName: "Scailo.OutwardJobsService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.OutwardJobsService.Create
         */
        create: {
            name: "Create",
            I: outward_jobs_scailo_pb_js_1.OutwardJobsServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.OutwardJobsService.Draft
         */
        draft: {
            name: "Draft",
            I: outward_jobs_scailo_pb_js_1.OutwardJobsServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.OutwardJobsService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: outward_jobs_scailo_pb_js_1.OutwardJobsServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.OutwardJobsService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.OutwardJobsService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.OutwardJobsService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.OutwardJobsService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.OutwardJobsService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: outward_jobs_scailo_pb_js_1.OutwardJobsServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.OutwardJobsService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.OutwardJobsService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.OutwardJobsService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.OutwardJobsService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.OutwardJobsService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.RepeatWithDeliveryDate,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.OutwardJobsService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * @generated from rpc Scailo.OutwardJobsService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send Email
         *
         * @generated from rpc Scailo.OutwardJobsService.SendEmail
         */
        sendEmail: {
            name: "SendEmail",
            I: base_scailo_pb_js_1.IdentifierWithEmailAttributes,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Autofill the outward job
         *
         * @generated from rpc Scailo.OutwardJobsService.Autofill
         */
        autofill: {
            name: "Autofill",
            I: outward_jobs_scailo_pb_js_1.OutwardJobsServiceAutofillRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Checks if the Outward Job can be marked as completed (is true when all the inward items have been ordered and all the outward items have been dispatched)
         *
         * @generated from rpc Scailo.OutwardJobsService.IsCompletable
         */
        isCompletable: {
            name: "IsCompletable",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.BooleanResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Checks if the Inward items within an Outward Job have been ordered (through a Purchase Order)
         *
         * @generated from rpc Scailo.OutwardJobsService.IsOrdered
         */
        isOrdered: {
            name: "IsOrdered",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.BooleanResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Checks if the Outward items within an Outward Job have been dispatched (through Free Issue Material)
         *
         * @generated from rpc Scailo.OutwardJobsService.IsDispatched
         */
        isDispatched: {
            name: "IsDispatched",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.BooleanResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add multiple items to a outward job
         *
         * @generated from rpc Scailo.OutwardJobsService.AddMultipleOutwardJobInwardItems
         */
        addMultipleOutwardJobInwardItems: {
            name: "AddMultipleOutwardJobInwardItems",
            I: outward_jobs_scailo_pb_js_1.OutwardJobsServiceMultipleInwardItemsCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an inward item to a outward job
         *
         * @generated from rpc Scailo.OutwardJobsService.AddOutwardJobInwardItem
         */
        addOutwardJobInwardItem: {
            name: "AddOutwardJobInwardItem",
            I: outward_jobs_scailo_pb_js_1.OutwardJobsServiceInwardItemCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify an inward item in a outward job
         *
         * @generated from rpc Scailo.OutwardJobsService.ModifyOutwardJobInwardItem
         */
        modifyOutwardJobInwardItem: {
            name: "ModifyOutwardJobInwardItem",
            I: outward_jobs_scailo_pb_js_1.OutwardJobsServiceInwardItemUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve an inward item in a outward job
         *
         * @generated from rpc Scailo.OutwardJobsService.ApproveOutwardJobInwardItem
         */
        approveOutwardJobInwardItem: {
            name: "ApproveOutwardJobInwardItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an inward item in a outward job
         *
         * @generated from rpc Scailo.OutwardJobsService.DeleteOutwardJobInwardItem
         */
        deleteOutwardJobInwardItem: {
            name: "DeleteOutwardJobInwardItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder items in a outward job
         *
         * @generated from rpc Scailo.OutwardJobsService.ReorderOutwardJobInwardItems
         */
        reorderOutwardJobInwardItems: {
            name: "ReorderOutwardJobInwardItems",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Outward Job Inward Item by ID
         *
         * @generated from rpc Scailo.OutwardJobsService.ViewOutwardJobInwardItemByID
         */
        viewOutwardJobInwardItemByID: {
            name: "ViewOutwardJobInwardItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: outward_jobs_scailo_pb_js_1.OutwardJobInwardItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved outward job inward items for given outward job ID
         *
         * @generated from rpc Scailo.OutwardJobsService.ViewApprovedOutwardJobInwardItems
         */
        viewApprovedOutwardJobInwardItems: {
            name: "ViewApprovedOutwardJobInwardItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: outward_jobs_scailo_pb_js_1.OutwardJobsInwardItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved outward job inward items for given outward job ID
         *
         * @generated from rpc Scailo.OutwardJobsService.ViewUnapprovedOutwardJobInwardItems
         */
        viewUnapprovedOutwardJobInwardItems: {
            name: "ViewUnapprovedOutwardJobInwardItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: outward_jobs_scailo_pb_js_1.OutwardJobsInwardItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the outward job inward item
         *
         * @generated from rpc Scailo.OutwardJobsService.ViewOutwardJobInwardItemHistory
         */
        viewOutwardJobInwardItemHistory: {
            name: "ViewOutwardJobInwardItemHistory",
            I: outward_jobs_scailo_pb_js_1.OutwardJobInwardItemHistoryRequest,
            O: outward_jobs_scailo_pb_js_1.OutwardJobsInwardItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved outward job inward items for given outward job ID with pagination
         *
         * @generated from rpc Scailo.OutwardJobsService.ViewPaginatedApprovedOutwardJobInwardItems
         */
        viewPaginatedApprovedOutwardJobInwardItems: {
            name: "ViewPaginatedApprovedOutwardJobInwardItems",
            I: outward_jobs_scailo_pb_js_1.OutwardJobInwardItemsSearchRequest,
            O: outward_jobs_scailo_pb_js_1.OutwardJobsServicePaginatedInwardItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved outward job inward items for given outward job ID with pagination
         *
         * @generated from rpc Scailo.OutwardJobsService.ViewPaginatedUnapprovedOutwardJobInwardItems
         */
        viewPaginatedUnapprovedOutwardJobInwardItems: {
            name: "ViewPaginatedUnapprovedOutwardJobInwardItems",
            I: outward_jobs_scailo_pb_js_1.OutwardJobInwardItemsSearchRequest,
            O: outward_jobs_scailo_pb_js_1.OutwardJobsServicePaginatedInwardItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through outward job inward items with pagination
         *
         * @generated from rpc Scailo.OutwardJobsService.SearchInwardItemsWithPagination
         */
        searchInwardItemsWithPagination: {
            name: "SearchInwardItemsWithPagination",
            I: outward_jobs_scailo_pb_js_1.OutwardJobInwardItemsSearchRequest,
            O: outward_jobs_scailo_pb_js_1.OutwardJobsServicePaginatedInwardItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download the CSV template that could be used to upload inward items from the filled CSV file
         *
         * @generated from rpc Scailo.OutwardJobsService.DownloadInwardItemsCSVTemplate
         */
        downloadInwardItemsCSVTemplate: {
            name: "DownloadInwardItemsCSVTemplate",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Upload inward items using a CSV file. This is an idempotent operation. All the existing items are deleted before adding the items from the file.
         *
         * @generated from rpc Scailo.OutwardJobsService.UploadOutwardJobInwardItems
         */
        uploadOutwardJobInwardItems: {
            name: "UploadOutwardJobInwardItems",
            I: base_scailo_pb_js_1.IdentifierUUIDWithFile,
            O: base_scailo_pb_js_1.IdentifiersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add multiple items to a outward job
         *
         * @generated from rpc Scailo.OutwardJobsService.AddMultipleOutwardJobOutwardItems
         */
        addMultipleOutwardJobOutwardItems: {
            name: "AddMultipleOutwardJobOutwardItems",
            I: outward_jobs_scailo_pb_js_1.OutwardJobsServiceMultipleOutwardItemsCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an outward item to a outward job
         *
         * @generated from rpc Scailo.OutwardJobsService.AddOutwardJobOutwardItem
         */
        addOutwardJobOutwardItem: {
            name: "AddOutwardJobOutwardItem",
            I: outward_jobs_scailo_pb_js_1.OutwardJobsServiceOutwardItemCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify an outward item in a outward job
         *
         * @generated from rpc Scailo.OutwardJobsService.ModifyOutwardJobOutwardItem
         */
        modifyOutwardJobOutwardItem: {
            name: "ModifyOutwardJobOutwardItem",
            I: outward_jobs_scailo_pb_js_1.OutwardJobsServiceOutwardItemUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve an outward item in a outward job
         *
         * @generated from rpc Scailo.OutwardJobsService.ApproveOutwardJobOutwardItem
         */
        approveOutwardJobOutwardItem: {
            name: "ApproveOutwardJobOutwardItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an outward item in a outward job
         *
         * @generated from rpc Scailo.OutwardJobsService.DeleteOutwardJobOutwardItem
         */
        deleteOutwardJobOutwardItem: {
            name: "DeleteOutwardJobOutwardItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder items in a outward job
         *
         * @generated from rpc Scailo.OutwardJobsService.ReorderOutwardJobOutwardItems
         */
        reorderOutwardJobOutwardItems: {
            name: "ReorderOutwardJobOutwardItems",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Outward Job Outward Item by ID
         *
         * @generated from rpc Scailo.OutwardJobsService.ViewOutwardJobOutwardItemByID
         */
        viewOutwardJobOutwardItemByID: {
            name: "ViewOutwardJobOutwardItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: outward_jobs_scailo_pb_js_1.OutwardJobOutwardItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved outward job outward items for given outward job ID
         *
         * @generated from rpc Scailo.OutwardJobsService.ViewApprovedOutwardJobOutwardItems
         */
        viewApprovedOutwardJobOutwardItems: {
            name: "ViewApprovedOutwardJobOutwardItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: outward_jobs_scailo_pb_js_1.OutwardJobsOutwardItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved outward job outward items for given outward job ID
         *
         * @generated from rpc Scailo.OutwardJobsService.ViewUnapprovedOutwardJobOutwardItems
         */
        viewUnapprovedOutwardJobOutwardItems: {
            name: "ViewUnapprovedOutwardJobOutwardItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: outward_jobs_scailo_pb_js_1.OutwardJobsOutwardItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the outward job outward item
         *
         * @generated from rpc Scailo.OutwardJobsService.ViewOutwardJobOutwardItemHistory
         */
        viewOutwardJobOutwardItemHistory: {
            name: "ViewOutwardJobOutwardItemHistory",
            I: outward_jobs_scailo_pb_js_1.OutwardJobOutwardItemHistoryRequest,
            O: outward_jobs_scailo_pb_js_1.OutwardJobsOutwardItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved outward job outward items for given outward job ID with pagination
         *
         * @generated from rpc Scailo.OutwardJobsService.ViewPaginatedApprovedOutwardJobOutwardItems
         */
        viewPaginatedApprovedOutwardJobOutwardItems: {
            name: "ViewPaginatedApprovedOutwardJobOutwardItems",
            I: outward_jobs_scailo_pb_js_1.OutwardJobOutwardItemsSearchRequest,
            O: outward_jobs_scailo_pb_js_1.OutwardJobsServicePaginatedOutwardItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved outward job outward items for given outward job ID with pagination
         *
         * @generated from rpc Scailo.OutwardJobsService.ViewPaginatedUnapprovedOutwardJobOutwardItems
         */
        viewPaginatedUnapprovedOutwardJobOutwardItems: {
            name: "ViewPaginatedUnapprovedOutwardJobOutwardItems",
            I: outward_jobs_scailo_pb_js_1.OutwardJobOutwardItemsSearchRequest,
            O: outward_jobs_scailo_pb_js_1.OutwardJobsServicePaginatedOutwardItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through outward job outward items with pagination
         *
         * @generated from rpc Scailo.OutwardJobsService.SearchOutwardItemsWithPagination
         */
        searchOutwardItemsWithPagination: {
            name: "SearchOutwardItemsWithPagination",
            I: outward_jobs_scailo_pb_js_1.OutwardJobOutwardItemsSearchRequest,
            O: outward_jobs_scailo_pb_js_1.OutwardJobsServicePaginatedOutwardItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download the CSV template that could be used to upload outward items from the filled CSV file
         *
         * @generated from rpc Scailo.OutwardJobsService.DownloadOutwardItemsCSVTemplate
         */
        downloadOutwardItemsCSVTemplate: {
            name: "DownloadOutwardItemsCSVTemplate",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Upload outward items using a CSV file. This is an idempotent operation. All the existing items are deleted before adding the items from the file.
         *
         * @generated from rpc Scailo.OutwardJobsService.UploadOutwardJobOutwardItems
         */
        uploadOutwardJobOutwardItems: {
            name: "UploadOutwardJobOutwardItems",
            I: base_scailo_pb_js_1.IdentifierUUIDWithFile,
            O: base_scailo_pb_js_1.IdentifiersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add a contact
         *
         * @generated from rpc Scailo.OutwardJobsService.AddOutwardJobContact
         */
        addOutwardJobContact: {
            name: "AddOutwardJobContact",
            I: outward_jobs_scailo_pb_js_1.OutwardJobsServiceContactCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve a contact
         *
         * @generated from rpc Scailo.OutwardJobsService.ApproveOutwardJobContact
         */
        approveOutwardJobContact: {
            name: "ApproveOutwardJobContact",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete a contact
         *
         * @generated from rpc Scailo.OutwardJobsService.DeleteOutwardJobContact
         */
        deleteOutwardJobContact: {
            name: "DeleteOutwardJobContact",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View a contact for the given ID
         *
         * @generated from rpc Scailo.OutwardJobsService.ViewOutwardJobContactByID
         */
        viewOutwardJobContactByID: {
            name: "ViewOutwardJobContactByID",
            I: base_scailo_pb_js_1.Identifier,
            O: outward_jobs_scailo_pb_js_1.OutwardJobContact,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all contacts for given outward job UUID
         *
         * @generated from rpc Scailo.OutwardJobsService.ViewOutwardJobContacts
         */
        viewOutwardJobContacts: {
            name: "ViewOutwardJobContacts",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: outward_jobs_scailo_pb_js_1.OutwardJobContactsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.OutwardJobsService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: outward_jobs_scailo_pb_js_1.OutwardJob,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID
         *
         * @generated from rpc Scailo.OutwardJobsService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: outward_jobs_scailo_pb_js_1.OutwardJob,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the ancillary parameters (UUIDs of the internal references) by UUID
         *
         * @generated from rpc Scailo.OutwardJobsService.ViewAncillaryParametersByUUID
         */
        viewAncillaryParametersByUUID: {
            name: "ViewAncillaryParametersByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: outward_jobs_scailo_pb_js_1.OutwardJobAncillaryParameters,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.OutwardJobsService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: outward_jobs_scailo_pb_js_1.OutwardJobsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.OutwardJobsService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: outward_jobs_scailo_pb_js_1.OutwardJobsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.OutwardJobsService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: outward_jobs_scailo_pb_js_1.OutwardJobsServicePaginationReq,
            O: outward_jobs_scailo_pb_js_1.OutwardJobsServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all purchase order IDs that are associated with the given outward job ID
         *
         * @generated from rpc Scailo.OutwardJobsService.ViewAssociatedPurchaseOrderIDs
         */
        viewAssociatedPurchaseOrderIDs: {
            name: "ViewAssociatedPurchaseOrderIDs",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.IdentifiersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective inward items for the given outward job
         *
         * @generated from rpc Scailo.OutwardJobsService.ViewProspectiveInwardFamilies
         */
        viewProspectiveInwardFamilies: {
            name: "ViewProspectiveInwardFamilies",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective outward job inward item info for the given family ID and outward job ID
         *
         * @generated from rpc Scailo.OutwardJobsService.ViewProspectiveOutwardJobInwardItem
         */
        viewProspectiveOutwardJobInwardItem: {
            name: "ViewProspectiveOutwardJobInwardItem",
            I: outward_jobs_scailo_pb_js_1.OutwardJobInwardItemProspectiveInfoRequest,
            O: outward_jobs_scailo_pb_js_1.OutwardJobsServiceInwardItemCreateRequest,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective outward items for the given outward job
         *
         * @generated from rpc Scailo.OutwardJobsService.ViewProspectiveOutwardFamilies
         */
        viewProspectiveOutwardFamilies: {
            name: "ViewProspectiveOutwardFamilies",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective outward job outward item info for the given family ID and outward job ID
         *
         * @generated from rpc Scailo.OutwardJobsService.ViewProspectiveOutwardJobOutwardItem
         */
        viewProspectiveOutwardJobOutwardItem: {
            name: "ViewProspectiveOutwardJobOutwardItem",
            I: outward_jobs_scailo_pb_js_1.OutwardJobOutwardItemProspectiveInfoRequest,
            O: outward_jobs_scailo_pb_js_1.OutwardJobsServiceOutwardItemCreateRequest,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download outward job with the given IdentifierUUID (can be used to allow public downloads)
         *
         * @generated from rpc Scailo.OutwardJobsService.DownloadByUUID
         */
        downloadByUUID: {
            name: "DownloadByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.OutwardJobsService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: outward_jobs_scailo_pb_js_1.OutwardJobsServiceSearchAllReq,
            O: outward_jobs_scailo_pb_js_1.OutwardJobsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.OutwardJobsService.Filter
         */
        filter: {
            name: "Filter",
            I: outward_jobs_scailo_pb_js_1.OutwardJobsServiceFilterReq,
            O: outward_jobs_scailo_pb_js_1.OutwardJobsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.OutwardJobsService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.OutwardJobsService.Count
         */
        count: {
            name: "Count",
            I: outward_jobs_scailo_pb_js_1.OutwardJobsServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
